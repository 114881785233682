import { ReactElement, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { INPUT_TYPE } from '../../../../constants';
import StepperCreate from '../../components/StepperCreate';
import { pagePaths } from '../../config';
import { getServiceRequestLight, saveServiceRequestLight } from '../../helpers/helpers';
import { useServiceRequestLightSetupOption } from '../../hooks/useServiceRequestLightSetupOption';
import { useServiceRequestLightTranslation } from '../../hooks/useServiceRequestLightTranslation';
import { FormFields } from '../../types';

import Button from '@/components/atoms/Button';
import FormLine from '@/components/atoms/FormLine/FormLine';
import { HookInput as Input } from '@/components/atoms/Input';
import { highlightSearchedWords, HookSelect } from '@/components/atoms/Select';
import Textarea from '@/components/atoms/Textarea';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import FormComponent from '@/components/molecules/Form';
import { requiredRule } from '@/components/molecules/Form/helpers';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { useGetSiteContactsQuery } from '@/modules/ServiceRequest/api/index';
import { requestCategories } from '@/modules/ServiceRequest/config';
import {
  formatPossibleAffectedPersonsList,
  renderAffectedPersonName,
} from '@/modules/ServiceRequest/helpers/helpers';
import SwitchSite from '@/modules/Sites/components/SwitchSite';
import { State } from '@/types/state.types';

import styles from './Create.module.css';

const MAX_FILES_NUM = 5;
const MAX_FILE_SIZE = 1024 * 1024 * 10;
const MAX_TOTAL_FILE_SIZE = 1024 * 1024 * 21;
const MAX_PERSONS_LIST_OPTIONS_DISPLAYED = 5;
const MIN_CHARACTERS_TO_PERFORM_SEARCH = 3;

const Create = (): ReactElement => {
  const { label } = useServiceRequestLightTranslation(__filename);
  const history = useHistory();
  const historyState = history.location.state as { from?: string };
  const isBackToSummary = historyState?.from === pagePaths.Summary;
  const [search, setSearch] = useState<string>('');
  const siteName = useSelector((state: State) => state.Core?.context?.site?.name || '');
  const currentUser = useSelector((state: State) => state.Core?.user);

  const {
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { isValid },
    setValue,
  } = useFormContext<FormFields>();

  const values = getValues();
  const [storedRequests, setStoredRequests] = useState<FormFields | undefined>(undefined);
  const { data: siteContacts, isFetching: contactsLoading } = useGetSiteContactsQuery(
    {
      pageSize: MAX_PERSONS_LIST_OPTIONS_DISPLAYED,
      search,
    },
    { skip: search.length < MIN_CHARACTERS_TO_PERFORM_SEARCH }
  );

  useEffect(() => {
    const fetchCachedRequests = async () => {
      const res = await getServiceRequestLight();
      setStoredRequests(res);
    };

    fetchCachedRequests().catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (storedRequests) {
      setValue('phoneNumber', storedRequests.phoneNumber);
      setValue('buildingText', storedRequests.buildingText);
      setValue('category', storedRequests.category ?? '');
      setValue('description', storedRequests.description);
      setValue('roomText', storedRequests.roomText);
      setValue('title', storedRequests.title);
      setValue('attachments', storedRequests.attachments);
    }
  }, [storedRequests, setValue]);

  const onSubmit: SubmitHandler<FormFields> = useCallback(() => {
    history.push(pagePaths.Summary);
  }, [history]);

  const formHandler = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      saveServiceRequestLight(getValues());
      handleSubmit(onSubmit)();
    },
    [handleSubmit, onSubmit, getValues]
  );

  const phoneNumberRequiredFieldRule = requiredRule(label('Ref: Your phone number'), label);
  phoneNumberRequiredFieldRule.validate.match = (value: string) =>
    value.match(/^[0-9+]{6,}$/) ? true : label('Ref: PhoneNumber error msg');

  const getAffectedPersonNoOptionsMessage = ({ inputValue }: any) => {
    if (contactsLoading) {
      return label('Loading...');
    }

    if (inputValue === '') {
      return label('Ref: Start typing');
    }

    if (inputValue.length < MIN_CHARACTERS_TO_PERFORM_SEARCH) {
      return label('Ref: Type at least 3 characters to search');
    }

    if (siteContacts && siteContacts.length < 1) {
      return label('Ref: No affected person results');
    }
  };
  const showAffectedPerson = useServiceRequestLightSetupOption('enableAffectedPerson');
  const showCategories = useServiceRequestLightSetupOption('enableCategories');

  useEffect(() => {
    if (!showAffectedPerson) {
      setSearch('');
    }
  }, [showAffectedPerson, setSearch]);
  (!showAffectedPerson || values.affectedPerson?.value === '') &&
    setValue('affectedPerson', {
      label: renderAffectedPersonName(
        currentUser.firstName || '',
        currentUser.lastName || '',
        currentUser.email || '',
        label,
        true,
        true
      ) as string,
      value: currentUser.contactId || '',
    });

  return (
    <SimpleFormPage
      title={label('Ref: ServiceRequestLightTitle')}
      data-testid="service-request-light-form"
      onBackButtonClick={isBackToSummary ? undefined : reset}
    >
      <Container>
        <Column.Main>
          <SwitchSite
            sentence={label('Ref: You are requesting service on the site {siteName}.', {
              replace: { siteName },
            })}
            redirectTo={pagePaths.Create}
          />
          <Title size={TITLE_SIZE.HEADLINES}>{label('Ref: Your request')}</Title>
          <Card>
            <FormComponent onSubmit={formHandler}>
              <div className={styles.formColumn}>
                <FormLine data-testid="service-request-light-summary">
                  <Input
                    id="summary"
                    data-testid="service-request-light-summary"
                    name="title"
                    inputLabel={label('Ref: Summary')}
                    maxlength={150}
                    size={150}
                    required
                    control={control}
                    rules={requiredRule(label('Ref: Summary'), label)}
                  />
                </FormLine>
                {showCategories && (
                  <FormLine data-testid="service-request-light-category">
                    <HookSelect
                      aria-label={label('Ref: Request category')}
                      data-testid="category"
                      name="category"
                      control={control}
                      label={label('Ref: Request category')}
                      options={requestCategories.map((cat) => ({ label: label(cat), value: cat }))}
                    />
                  </FormLine>
                )}
              </div>
              {showAffectedPerson && (
                <FormLine data-testid="service-request-light-affected-person">
                  <Controller
                    name="affectedPerson"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <HookSelect
                        openMenuOnFocus={false}
                        aria-label={label('Ref: Person affected')}
                        data-testid="affectedPerson"
                        name="affectedPerson"
                        label={label('Ref: Person affected')}
                        required
                        options={
                          search.length < MIN_CHARACTERS_TO_PERFORM_SEARCH
                            ? []
                            : formatPossibleAffectedPersonsList(siteContacts, label, currentUser)
                        }
                        onChange={onChange}
                        onInputChange={(newValue: string) => setSearch(newValue)}
                        noOptionsMessage={getAffectedPersonNoOptionsMessage}
                        formatOptionLabel={highlightSearchedWords}
                      />
                    )}
                  />
                </FormLine>
              )}
              <FormLine data-testid="service-request-light-phone">
                <Input
                  id="phoneNumber"
                  data-testid="service-request-light-phone"
                  name="phoneNumber"
                  inputLabel={label('Ref: Your phone number')}
                  inputType={INPUT_TYPE.TEL}
                  inputmode="tel"
                  autocomplete="tel"
                  maxlength={24}
                  size={24}
                  control={control}
                  required={false}
                  rules={phoneNumberRequiredFieldRule}
                />
              </FormLine>
              <div className={styles.formColumn}>
                <FormLine data-testid="service-request-light-building">
                  <Input
                    id="building"
                    data-testid="service-request-light-building"
                    name="buildingText"
                    inputLabel={label('Ref: Building')}
                    maxlength={150}
                    size={150}
                    control={control}
                  />
                </FormLine>
                <FormLine data-testid="service-request-light-room">
                  <Input
                    id="room"
                    data-testid="service-request-light-room"
                    name="roomText"
                    inputLabel={label('Ref: Room')}
                    maxlength={150}
                    size={150}
                    control={control}
                  />
                </FormLine>
              </div>
              <FormLine data-testid="service-request-light-description">
                <Textarea
                  id="description"
                  data-testid="description"
                  name="description"
                  label={label('Ref: Description')}
                  control={control}
                  required
                  rules={requiredRule(label('Ref: Description'), label)}
                />
                <span className={styles.helperText}>{label('Ref: Description help text')}</span>
              </FormLine>
              <FormLine data-testid="service-request-light-attachments">
                <Controller
                  name="attachments"
                  render={({ field: { value, onChange } }) => (
                    <Attachments
                      data-testid="attachments"
                      maxFilesNum={MAX_FILES_NUM}
                      maxFileSize={MAX_FILE_SIZE}
                      maxTotalFileSize={MAX_TOTAL_FILE_SIZE}
                      attach={onChange}
                      attachments={value}
                    />
                  )}
                />
              </FormLine>
            </FormComponent>
            <Title tag={TITLE_TAG.SPAN} size={TITLE_SIZE.BODYSBOLD}>
              {label('Ref: Required')}
            </Title>
          </Card>
        </Column.Main>
        <Column.Side>
          <StepperCreate stepIndex={0} />
        </Column.Side>
        <ActionsBar>
          <Button
            onClick={formHandler}
            disabled={!isValid}
            data-testid="service-request-light-form-next-button"
            isClickDisabled
          >
            {label('Ref: Next')}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default Create;
