const labels = {
  PrivacyPolicy: 'Chính sách bảo mật',
  TermsOfSale: 'Điều khoản bán hàng',
  TermsOfUse: 'Điều khoản & điền kiện',
  home: 'trang chủ',
  anytime: 'Bất cứ lúc nào ',
  anywhere: 'bất cứ nơi nào',
  Download: 'Tải xuống',
  scrollRightButton: 'Cuộn danh mục ở bên phải',
  scrollLeftButton: 'Cuộn danh mục ở bên trái',
  calendar: 'Lịch',
  'See more': 'Xem thêm',
  'See less': 'Thấy ít',
  'from (followed by a date, or date and time)': 'từ',
  'to (followed by a date, or date and time)': 'đến',
  'on (followed by a date)': 'Trên',
  'at (followed by time)': 'Tại',
  'from (followed by a location)': 'từ',
  'to (followed by a location)': 'đến',
  'on (followed by a site name)': 'Trên',
  'from (followed by a person)': 'từ',
  previous: 'Trước',
  next: 'Kế tiếp ',
  success: 'sự thành công',
  failure: 'sự thất bại',
  today: 'Hôm nay',
  tomorrow: 'Ngày mai',
  Monday: 'Thứ hai',
  Tuesday: 'Thứ ba',
  Wednesday: 'Thứ tư',
  Thursday: 'Thứ năm',
  Friday: 'Thứ sáu',
  Saturday: 'Thứ bảy',
  Sunday: 'Chủ nhật',
  everyday: 'Hằng ngày',
  'open all day': 'Mở cửa cả ngày',
  'opening hours': 'Giờ mở cửa',
  status: 'tình trạng',
  'to be confirmed': 'Để được xác nhận',
  'only (as in filter only this)': 'chỉ còn',
  edit: 'biên tập',
  submit: 'Gởi đi',
  cancel: 'hủy',
  confirm: 'Xác nhận',
  reset: 'Cài lại',
  Delete: 'Xóa bỏ',
  select: 'lựa chọn',
  switch: 'công tắc điện',
  close: 'Đóng ',
  clear: 'thông thoáng',
  remove: 'tẩy',
  logout: 'đăng xuất',
  go: 'đi',
  'see (as in consult this page or document)': 'xem',
  'see all (as in see all items)': 'nhìn tất cả',
  'see more (as in see more details for this item)': 'Xem thêm',
  retry: 'thử lại',
  'Ref: ok': 'Được',
  'yes (as in yes I accept)': 'Vâng',
  'no (as in no I do not accept)': 'Không',
  you: 'bạn',
  'thank you': 'Cảm ơn ',
  'sorry (standalone)': 'Xin lỗi',
  'to home (as in navigate to the homepage)': 'đến trang chủ',
  map: 'bản đồ',
  list: 'danh sách',
  'Loading...': 'Đang tải',
  'Refreshing...': 'Đang cập nhật ...',
  'Please select': 'Xin hãy lựa chọn',
  'Switch site': 'Chuyển trang web ở đây',
  'File size cannot exceed {max file size}': 'Kích thước tệp không được vượt quá {max file size}',
  Filters: 'Bộ lọc',
  'Add attachment': 'Thêm tập tin đính kèm',
  Search: 'Tìm kiếm',
  'More (as in: see more info)': 'Thêm',
  'You can attach a maximum of {max files number} files.':
    'Bạn có thể đính kèm tối đa {max files number} tệp.',
  'There are no messages to display.': 'Không có thông báo nào để hiển thị.',
  'Your selection did not return any result.': 'Lựa chọn của bạn không trả lại bất kỳ kết quả nào.',
  'Ref: Popup header for missing required fields in a form': 'Vui lòng cung cấp thông tin bổ sung',
  'Ref: Required field': '{ref} là trường bắt buộc.',
  email: 'E-mail',
  phone: 'Số điện thoại',
  Account: 'tài khoản của tôi',
  Contacts: 'Liên lạc',
  Content: 'Các nội dung',
  Events: 'Sự kiện',
  Facilities: 'Cơ sở ',
  Shop: 'Cửa hàng',
  Menu: 'Thực đơn',
  Menus: 'Thực đơn',
  Order: 'Gọi món',
  Orders: 'đơn hàng',
  Info: 'Thông tin',
  FAQ: 'Câu hỏi thường gặp',
  Feedback: 'Bình luận',
  Review: 'Xét lại',
  Requests: 'yêu cầu',
  'Request Helpdesk': 'Yêu cầu trợ giúp',
  Location: 'Vị trí',
  Safety: 'Sự an toàn',
  FreeCoffee: 'Cà phê miễn phí',
  Newsfeed: 'Newsfeed',
  MyOrders: 'Đơn đặt hàng của tôi',
  LoyaltyStamps: 'Tem trung thành',
  Stamps: 'Tem',
  Stays: 'ở lại',
  Suppliers: 'Các nhà cung cấp',
  Surveys: 'khảo sát',
  notifications: 'thông báo.',
  cart: 'xe đẩy',
  'Ref: Reset your order': 'Đặt lại đơn đặt hàng của bạn',
  'Ref: My language': 'Ngôn ngữ của tôi',
  Validate: 'Xác nhận',
  'contact us': 'liên hệ chúng tôi',
  'Ref: Start typing': 'Bắt đầu gõ',
  Occupancy: 'Sự chiếm đóng',
  'see less': 'Thấy ít',
  'see more': 'Xem thêm',
  contacts: 'Liên lạc',
  Imprint: 'Dấu ấn',
  'Ref: camera access request title': 'Cho phép truy cập vào máy ảnh',
  'Ref: camera access request body':
    'Bạn phải kích hoạt việc sử dụng máy ảnh trên trang web này để sử dụng máy quét mã QR',
  or: 'hoặc',
  'Ref: Input required': '{input_name} là bắt buộc',
  'Enter a valid email address': 'Nhập địa chỉ email hợp lệ',
  'Ref: Input error E-mail already exists':
    'Đã có một tài khoản với email này bạn đã nhập. Vui lòng đăng nhập thay thế.',
  'Password must contain': 'Mật khẩu cần phải bảo vệ:',
  'Ref: Input password error strength':
    'Mật khẩu phải chứa ít nhất 8 ký tự, 1 số, 1 chữ cái và 1 ký tự đặc biệt',
  Password: 'Mật khẩu',
  'New password': 'Mật khẩu mới',
  'Confirm password': 'Xác nhận mật khẩu',
  'Ref: Password does not match': 'Mật khẩu và xác nhận mật khẩu không phù hợp',
  'Ref: Old password does not match': 'mặt khẩu cũ không khớp',
  'Ref: Password validation min8Characters': 'Ít nhất 8 ký tự',
  'Ref: Password validation min1Number': 'Ít nhất 1 chữ số',
  'Ref: Password validation min1Letter': 'Ít nhất 1 chữ cái',
  'Ref: Password validation min1SpecialChar': 'Ít nhất 1 nhân vật đặc biệt',
  Continue: 'Tiếp tục',
  'Enter a valid phone number': 'Nhập số điện thoại hợp lệ',
  'Phone is required': 'Điện thoại là bắt buộc',
  'Go to home': 'Đi về nhà',
  'Something went wrong': 'Có gì đó đã sai',
  'Something went wrong, data not loaded': 'Có gì đó không ổn, dữ liệu không được tải',
  'Change password': 'Đổi mật khẩu',
  'remove one': 'loại bỏ một',
  'add one': 'cộng một',
  'Confirm mobile phone': 'Xác nhận điện thoại di động',
  'Confirm Filters': 'Xác nhận bộ lọc',
  'Reset Filters': 'Đặt lại bộ lọc',
  '{selectedNum} active filters': '{selectedNum} Bộ lọc hoạt động',
  'Wrong input icon': 'Biểu tượng đầu vào sai',
  'Correct input icon': 'Biểu tượng đầu vào chính xác',
  'Show input text icon': 'Hiển thị biểu tượng văn bản đầu vào',
  'Hide input text icon': 'Ẩn biểu tượng văn bản đầu vào',
  'Required fields info': '* Phần bắt buộc',
  'Ref: Select site on the map': 'Chọn trang web của bạn trên bản đồ',
  'Ref: Geolocation disabled':
    'Định vị địa lý bị vô hiệu hóa. Để xem các trang web gần đó, xin vui lòng bật nó.',
  'Ref: Reaction - LIKE': 'Thích',
  'Ref: Reaction - LOVE': 'Yêu và quý',
  'Ref: Reaction - INTERESTING': 'Hấp dẫn',
  'Ref: Reaction - CONGRATE': 'Chúc mừng',
  'Ref: Share': 'Đăng lại',
  'Ref: Send by Email': 'Gửi bằng thư điện tử',
  'Ref: Copy the link': 'Sao chép liên kết',
  'Ref: Link was copied': 'Liên kết đã được sao chép',
  'Ref: unable to copy url': 'Không thể sao chép URL',
  'Ref: External link': 'Liên kết bên ngoài',
  'Ref: Step': 'Bước chân',
  'input field': 'trường đầu vào',
  'Ref: Zoom': 'Phóng',
  'Increase hours': 'Tăng giờ',
  'Decrease hours': 'Giảm giờ',
  Hours: 'Giờ',
  Minutes: 'Phút',
  'Ref: filter label: favorites': 'Chỉ thể hiện mục yêu thích của tôi',
  'Ref: filter label: max calories': 'Calo tối đa',
  'Ref: filter label: dishes': 'Đĩa',
  'Ref: filter label: allergens': 'Dị ứng ',
  'Ref: filter label: highlight': 'Đánh dấu hoặc ẩn các bộ lọc với các món ăn',
  'Ref: Highlight': 'Điểm nổi bật',
  'Ref: Hide': 'Trốn',
  'Ref: Vegan': 'Chay',
  'Ref: Vegeterian': 'Ăn chay',
  'Ref: Mindful': 'Trách nhiệm',
  'Ref: Highlight text':
    'Tại đây bạn có thể chọn Hightlight hoặc ẩn các món ăn trên menu có chứa một số chất gây dị ứng nhất định',
  'Ref: Just now': 'Vừa rồi',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} phút trước',
  'Ref: 1 hour ago': '1 giờ trước',
  'Ref: {amountHours} hours ago': '{amountHours} giờ trước',
  'Ref: Yesterday at {time}': 'Hôm qua tại {time}',
  'Ref: {date} at {time}': '{date} tại {time}',
  'Ref: filter label: date': 'Ngày',
  'Ref: expand menu': 'Mở rộng menu',
  'Ref: collapse menu': 'Menu sụp đổ',
  'Ref: My site': 'Trang web của tôi',
  'Ref: Recent': 'Gần đây',
  'Ref: Services': 'Dịch vụ',
  'Ref: products in cart': 'Số lượng sản phẩm trong xe đẩy',
  'Ref: no product in cart': 'Không có sản phẩm trong xe đẩy',
  'Ref: Survey': 'Sự khảo sát',
  'Access to camera was denied. Please allow access and try again':
    'Truy cập vào máy ảnh đã bị từ chối. Vui lòng cho phép truy cập và thử lại',
  'Add an attachment': 'Thêm một tệp đính kèm',
  'Take a picture': 'Chụp ảnh',
  'Add a file from your device': 'Thêm một tệp từ thiết bị của bạn',
  'Ref: Loading page content, please wait': 'Đang tải nội dung trang, vui lòng đợi',
  Star: 'Ngôi sao',
  Stars: 'Sao',
  'Accommodation Request': 'Yêu cầu chỗ ở',
  'Ref: Guest Accomodation Request': 'Yêu cầu của khách',
  'Ref: Search a menu, product or facility': 'Tìm kiếm một menu, sản phẩm hoặc cơ sở',
  ConnectionLost: 'Kết nối mạng bị mất',
  ConnectionRestored: 'Kết nối mạng được khôi phục',
  'Ref: Skip to main content': 'Bỏ qua nội dung chính',
  'Ref: No options': 'Không có lựa chọn',
  'Ref: Global search modal': 'Phương thức tìm kiếm toàn cầu',
  'Ref: Search a facility': 'Tìm kiếm một cơ sở',
  'Ref: Search a menu or product': 'Tìm kiếm một menu hoặc sản phẩm',
  'Ref: Reaction': 'Sự phản ứng lại',
  'Ref: times': 'thời gian',
  'Ref: reaction. Change reaction': 'sự phản ứng lại. Thay đổi phản ứng',
  'Ref: No reaction. React': 'Không có phản ứng. Phản ứng',
  'Ref: LIKE': 'Giống',
  'Ref: LOVE': 'Yêu',
  'Ref: INTERESTING': 'Hấp dẫn',
  'Ref: CONGRATE': 'Xin chúc mừng',
  'Ref: Open search': 'Mở hộp tìm kiếm',
  'Ref: Close search': 'Đóng hộp tìm kiếm',
  'Ref: Total file size msg': 'Tổng kích thước tệp không thể vượt quá {max file size}',
  'Ref: Oops Title': 'Ối!',
  'Ref: Well Done': 'Làm tốt {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Mũi tên điều hướng - Tiếp tục',
  'Ref: Reload': 'Tải lại',
  'Ref: All Dates': 'Tất cả các ngày',
  'Ref: Today': 'Hôm nay',
  'Ref: Validate': 'Xác nhận',
  'Ref: toast error title': 'Ối!',
  'Ref: toast error subtitle': 'Có một lỗi đã xảy ra. Xin hãy thử lại.',
  'Ref: Calendar': 'Lịch',
  'Ref: Selected': 'Đã chọn',
  'Ref: Previous Month': 'Tháng trước',
  'Ref: Next Month': 'Tháng tiếp theo',
  'This module has been retired': 'Mô-đun này đã ngừng hoạt động',
  'Ref: Type at least 3 characters to search': 'Nhập ít nhất 3 ký tự để tìm kiếm',
};
export default labels;
