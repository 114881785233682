const labels = {
  PrivacyPolicy: 'политика конфиденциальности',
  TermsOfSale: 'Условия продаж',
  TermsOfUse: 'Правила и условия',
  home: 'Главная',
  anytime: 'Любое время',
  anywhere: 'куда угодно',
  Download: 'Скачать',
  scrollRightButton: 'Прокручивать категории справа',
  scrollLeftButton: 'Прокрутите категории слева',
  calendar: 'Календарь',
  'See more': 'Узнать больше',
  'See less': 'Увидеть меньше',
  'from (followed by a date, or date and time)': 'от',
  'to (followed by a date, or date and time)': 'к',
  'on (followed by a date)': 'На',
  'at (followed by time)': 'в',
  'from (followed by a location)': 'от',
  'to (followed by a location)': 'к',
  'on (followed by a site name)': 'На',
  'from (followed by a person)': 'от',
  previous: 'предыдущий',
  next: 'следующий',
  success: 'успех',
  failure: 'отказ',
  today: 'Cегодня',
  tomorrow: 'Завтра',
  Monday: 'понедельник',
  Tuesday: 'вторник',
  Wednesday: 'среда',
  Thursday: 'Четверг',
  Friday: 'пятница',
  Saturday: 'суббота',
  Sunday: 'Воскресенье',
  everyday: 'Каждый день',
  'open all day': 'Открыто весь день',
  'opening hours': 'Часы работы',
  status: 'статус',
  'to be confirmed': 'Уточняется',
  'only (as in filter only this)': 'Только',
  edit: 'редактировать',
  submit: 'Разместить',
  cancel: 'отменить',
  confirm: 'Подтверждение',
  reset: 'Перезагрузить',
  Delete: 'Удалить',
  select: 'Выбрать',
  switch: 'выключатель',
  close: 'Закрыть',
  clear: 'ясно',
  remove: 'удалять',
  logout: 'выйти',
  go: 'идти',
  'see (as in consult this page or document)': 'видеть',
  'see all (as in see all items)': 'Увидеть все',
  'see more (as in see more details for this item)': 'Узнать больше',
  retry: 'повторить попытку',
  'Ref: ok': 'В порядке',
  'yes (as in yes I accept)': 'да',
  'no (as in no I do not accept)': 'нет',
  you: 'ты',
  'thank you': 'Благодарю вас',
  'sorry (standalone)': 'простите',
  'to home (as in navigate to the homepage)': 'на главную страницу',
  map: 'карта',
  list: 'список',
  'Loading...': 'Loading ...',
  'Refreshing...': 'Обновление...',
  'Please select': 'Пожалуйста выберите',
  'Switch site': 'Переключить сайт здесь',
  'File size cannot exceed {max file size}': 'Размер файла не может превышать {max file size}',
  Filters: 'фильтры',
  'Add attachment': 'Добавить приложение',
  Search: 'Поиск',
  'More (as in: see more info)': 'Больше',
  'You can attach a maximum of {max files number} files.':
    'Вы можете прикрепить не более {max files number} файлов.',
  'There are no messages to display.': 'Нет сообщений для отображения.',
  'Your selection did not return any result.': 'Ваш выбор не дал никаких результатов.',
  'Ref: Popup header for missing required fields in a form':
    'Пожалуйста, предоставьте дополнительную информацию',
  'Ref: Required field': '{ref} является обязательным полем.',
  email: 'Эл. адрес',
  phone: 'Номер мобильного',
  Account: 'Счет',
  Contacts: 'контакты',
  Content: 'Содержание',
  Events: 'События',
  Facilities: 'оборудование',
  Shop: 'Магазин',
  Menu: 'Меню',
  Menus: 'Меню',
  Order: 'порядок',
  Orders: 'заказы',
  Info: 'Информация',
  FAQ: 'Часто задаваемые вопросы',
  Feedback: 'Обратная связь',
  Review: 'Рассмотрение',
  Requests: 'Запросы',
  'Request Helpdesk': 'Запросить службу поддержки',
  Location: 'Расположение',
  Safety: 'Безопасность',
  FreeCoffee: 'Бесплатный кофе',
  Newsfeed: 'Новостная лента',
  MyOrders: 'Мои заказы',
  LoyaltyStamps: 'Штампы лояльности',
  Stamps: 'Марки',
  Stays: 'Туры',
  Suppliers: 'Поставщики',
  Surveys: 'Обзоры',
  notifications: 'уверенность',
  cart: 'корзина',
  'Ref: Reset your order': 'Сбросить свой заказ',
  'Ref: My language': 'Мой язык',
  Validate: 'Проверять',
  'contact us': 'Связаться с нами',
  'Ref: Start typing': 'Начать печатать',
  Occupancy: 'Занятость',
  'see less': 'Смотреть меньше',
  'see more': 'Узнать больше',
  contacts: 'Контакты',
  Imprint: 'Отпечаток',
  'Ref: camera access request title': 'Разрешить доступ к камере',
  'Ref: camera access request body':
    'Вы должны включить использование камеры на этом сайте, чтобы использовать сканер QR -кода',
  or: 'или же',
  'Ref: Input required': '{input_name} требуется',
  'Enter a valid email address': 'Введите действительный адрес электронной почты',
  'Ref: Input error E-mail already exists':
    'Уже есть учетная запись с этим электронным письмом, которое вы ввели. Пожалуйста, войдите в систему.',
  'Password must contain': 'Пароль должен содержать:',
  'Ref: Input password error strength':
    'Пароль должен содержать не менее 8 символов, 1 номер, 1 буква и 1 специальный символ',
  Password: 'Пароль',
  'New password': 'Новый пароль',
  'Confirm password': 'Подтвердить Пароль',
  'Ref: Password does not match': 'Пароль и подтверждение пароля не совпадают',
  'Ref: Old password does not match': 'Старый пароль не соответствует',
  'Ref: Password validation min8Characters': 'Не менее 8 символов',
  'Ref: Password validation min1Number': 'Не менее 1 цифры',
  'Ref: Password validation min1Letter': 'Не менее 1 буквы',
  'Ref: Password validation min1SpecialChar': 'Не менее 1 особенного персонажа',
  Continue: 'Продолжать',
  'Enter a valid phone number': 'Введите действительный номер телефона',
  'Phone is required': 'Требуется телефон',
  'Go to home': 'Иди домой',
  'Something went wrong': 'Что-то пошло не так',
  'Something went wrong, data not loaded': 'Что -то пошло не так, данные не загружены',
  'Change password': 'Изменить пароль',
  'remove one': 'Удалить один',
  'add one': 'добавить один',
  'Confirm mobile phone': 'Подтвердить мобильный телефон',
  'Confirm Filters': 'Подтвердите фильтры',
  'Reset Filters': 'Сбросить фильтры',
  '{selectedNum} active filters': '{selectedNum} активные фильтры',
  'Wrong input icon': 'Неправильный значок ввода',
  'Correct input icon': 'Правильный значок ввода',
  'Show input text icon': 'Показать значок текста ввода',
  'Hide input text icon': 'Скрыть значок текста ввода',
  'Required fields info': '* Требуемые поля',
  'Ref: Select site on the map': 'Выберите свой сайт на карте',
  'Ref: Geolocation disabled':
    'Геолокация отключена. Чтобы увидеть близлежащие сайты, пожалуйста, включите это.',
  'Ref: Reaction - LIKE': 'Нравиться',
  'Ref: Reaction - LOVE': 'Любовь',
  'Ref: Reaction - INTERESTING': 'Интересно',
  'Ref: Reaction - CONGRATE': 'Поздравляют',
  'Ref: Share': 'Делиться',
  'Ref: Send by Email': 'Послать по электронной почте',
  'Ref: Copy the link': 'Скопировать ссылку',
  'Ref: Link was copied': 'Ссылка была скопирована',
  'Ref: unable to copy url': 'Невозможно скопировать URL',
  'Ref: External link': 'Внешняя ссылка',
  'Ref: Step': 'Шаг',
  'input field': 'поле ввода',
  'Ref: Zoom': 'Зум',
  'Increase hours': 'Увеличить часы',
  'Decrease hours': 'Уменьшить часы',
  Hours: 'Часы',
  Minutes: 'Минуты',
  'Ref: filter label: favorites': 'Покажи только мои любимые',
  'Ref: filter label: max calories': 'Максимальные калории',
  'Ref: filter label: dishes': 'Тарелки',
  'Ref: filter label: allergens': 'Аллергены',
  'Ref: filter label: highlight': 'Выделите или скрыть фильтры с блюдами',
  'Ref: Highlight': 'Выделять',
  'Ref: Hide': 'Скрывать',
  'Ref: Vegan': 'Веган',
  'Ref: Vegeterian': 'Вегетарианский',
  'Ref: Mindful': 'Внимательный, помнящий',
  'Ref: Highlight text':
    'Здесь вы можете выбрать Hightlight или скрыть блюда в меню, содержащую определенные аллергены',
  'Ref: Just now': 'Прямо сейчас',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} минуты назад',
  'Ref: 1 hour ago': '1 час назад',
  'Ref: {amountHours} hours ago': '{amountHours} Часы назад',
  'Ref: Yesterday at {time}': 'Вчера в {time}',
  'Ref: {date} at {time}': '{date} at {time}',
  'Ref: filter label: date': 'Свидание',
  'Ref: expand menu': 'Расширить меню',
  'Ref: collapse menu': 'Меню обрушения',
  'Ref: My site': 'Мой сайт',
  'Ref: Recent': 'Недавний',
  'Ref: Services': 'Услуги',
  'Ref: products in cart': 'Количество продуктов в тележке',
  'Ref: no product in cart': 'Нет продукта в тележке',
  'Ref: Survey': 'Опрос',
  'Access to camera was denied. Please allow access and try again':
    'Доступ к камере был отклонен. Пожалуйста, разрешите доступ и попробуйте еще раз',
  'Add an attachment': 'Добавьте вложение',
  'Take a picture': 'Сделать фото',
  'Add a file from your device': 'Добавьте файл с вашего устройства',
  'Ref: Loading page content, please wait': 'Загрузка содержимого страницы, пожалуйста, подождите',
  Star: 'Звезда',
  Stars: 'Звезда',
  'Accommodation Request': 'Запрос на проживание',
  'Ref: Guest Accomodation Request': 'Гостевой запрос',
  'Ref: Search a menu, product or facility': 'Поиск меню, продукта или объекта',
  ConnectionLost: 'Сетевое соединение потеряно',
  ConnectionRestored: 'Сетевое соединение восстановлено',
  'Ref: Skip to main content': 'Пропустить основной контент',
  'Ref: No options': 'Нет вариантов',
  'Ref: Global search modal': 'Глобальный поиск модал',
  'Ref: Search a facility': 'Поиск на объекте',
  'Ref: Search a menu or product': 'Поиск меню или продукта',
  'Ref: Reaction': 'Реакция',
  'Ref: times': 'времена',
  'Ref: reaction. Change reaction': 'реакция Изменение реакции',
  'Ref: No reaction. React': 'Нет реакции. Реагировать',
  'Ref: LIKE': 'Нравиться',
  'Ref: LOVE': 'Любовь',
  'Ref: INTERESTING': 'Интересный',
  'Ref: CONGRATE': 'Поздравляю',
  'Ref: Open search': 'Откройте поле поиска',
  'Ref: Close search': 'Закрыть поле поиска',
  'Ref: Total file size msg': 'Общий размер файла не может превышать {max file size}',
  'Ref: Oops Title': 'Уп!',
  'Ref: Well Done': 'Отличная работа {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Навигационная стрелка - продолжить',
  'Ref: Reload': 'Перезагрузить',
  'Ref: All Dates': 'Все даты',
  'Ref: Today': 'Сегодня',
  'Ref: Validate': 'Проверять',
  'Ref: toast error title': 'Уп!',
  'Ref: toast error subtitle': 'Произошла ошибка, попробуйте еще раз.',
  'Ref: Calendar': 'Календарь',
  'Ref: Selected': 'Выбран',
  'Ref: Previous Month': 'Предыдущий месяц',
  'Ref: Next Month': 'В следующем месяце',
  'This module has been retired': 'Этот модуль удален из эксплуатации',
  'Ref: Type at least 3 characters to search': 'Введите не менее 3 символов для поиска',
};
export default labels;
