import { store } from '../../../../store';
import {
  deletePushNotificationRegistrationData,
  savePushNotificationRegistrationData,
} from '../../helpers/notificationRegistrationHelper';
import mockResponse from '../../mocks/pushNotificationsApi';

import {
  RegisterPushNotificationsArgs,
  UnregisterPushNotificationsArgs,
} from './pushNotificationApi.Types';

import baseApi from '@/services/api/baseApi';

const pushNotificationsApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['pushNotifications'] })
  .injectEndpoints({
    endpoints: (build) => ({
      registerPushNotification: build.mutation<{}, RegisterPushNotificationsArgs>({
        query: (data) => ({
          url: '/v2/pushnotifications/register',
          method: 'post',
          data,
          mockData: mockResponse.registerPushNotification,
        }),
        transformResponse: (baseQueryReturnValue, _meta, arg) => {
          savePushNotificationRegistrationData({ ...arg, isSuccess: true });
          return baseQueryReturnValue;
        },
        transformErrorResponse(baseQueryReturnValue, _meta, arg) {
          savePushNotificationRegistrationData({
            ...arg,
            isSuccess: false,
            error: baseQueryReturnValue,
          });
          return baseQueryReturnValue;
        },
      }),
      unregisterPushNotification: build.mutation<{}, UnregisterPushNotificationsArgs>({
        query: (data) => ({
          url: '/v2/pushnotifications/unregister',
          method: 'delete',
          data,
          mockData: mockResponse.unregisterPushNotification,
        }),
        transformResponse: (response) => {
          deletePushNotificationRegistrationData();
          return response;
        },
        transformErrorResponse(response) {
          deletePushNotificationRegistrationData();
          return response;
        },
      }),
    }),
    overrideExisting: true,
  });

export const unregisterPushNotification = (args: UnregisterPushNotificationsArgs) =>
  store.dispatch(pushNotificationsApi.endpoints.unregisterPushNotification.initiate(args) as any);

export const { useRegisterPushNotificationMutation } = pushNotificationsApi;

export default pushNotificationsApi;
