const labels = {
  PrivacyPolicy: 'Integritetspolicy',
  TermsOfSale: 'Försäljningsvillkor',
  TermsOfUse: 'Allmänna Villkor',
  home: 'Hem',
  anytime: 'När som helst',
  anywhere: 'var som helst',
  Download: 'Ladda ner',
  scrollRightButton: 'Slullkategorier till höger',
  scrollLeftButton: 'Slullkategorier till vänster',
  calendar: 'Kalender',
  'See more': 'Se mer',
  'See less': 'Se mindre',
  'from (followed by a date, or date and time)': 'från',
  'to (followed by a date, or date and time)': 'till',
  'on (followed by a date)': 'Den',
  'at (followed by time)': 'på',
  'from (followed by a location)': 'från',
  'to (followed by a location)': 'till',
  'on (followed by a site name)': 'På',
  'from (followed by a person)': 'från',
  previous: 'Tidigare',
  next: 'Nästa',
  success: 'Lyckades',
  failure: 'Misslyckades',
  today: 'I dag',
  tomorrow: 'I morgon',
  Monday: 'Måndag',
  Tuesday: 'Tisdag',
  Wednesday: 'Onsdag',
  Thursday: 'Torsdag',
  Friday: 'fredag',
  Saturday: 'Lördag',
  Sunday: 'Söndag',
  everyday: 'Varje dag',
  'open all day': 'Öppna hela dagen',
  'opening hours': 'Öppettider',
  status: 'status',
  'to be confirmed': 'Väntar bekräftelse',
  'only (as in filter only this)': 'endast',
  edit: 'redigera',
  submit: 'Skicka in',
  cancel: 'Avbryt',
  confirm: 'Bekräfta',
  reset: 'Återställa',
  Delete: 'Radera',
  select: 'Välj',
  switch: 'byta',
  close: 'Stänga',
  clear: 'rensa',
  remove: 'ta bort',
  logout: 'logga ut',
  go: 'bekräfta',
  'see (as in consult this page or document)': 'ser',
  'see all (as in see all items)': 'Se allt',
  'see more (as in see more details for this item)': 'Se mer',
  retry: 'Försök igen',
  'Ref: ok': 'Ok',
  'yes (as in yes I accept)': 'Ja',
  'no (as in no I do not accept)': 'Nej',
  you: 'du',
  'thank you': 'Tack',
  'sorry (standalone)': 'förlåt',
  'to home (as in navigate to the homepage)': 'till hemmet',
  map: 'karta',
  list: 'lista',
  'Loading...': 'Laddar…',
  'Refreshing...': 'Uppdaterar…',
  'Please select': 'Vänligen välj',
  'Switch site': 'Byt sida här',
  'File size cannot exceed {max file size}': 'Filstorlek kan inte överstiga {max file size}',
  Filters: 'Filter',
  'Add attachment': 'Bifoga fil',
  Search: 'Sök',
  'More (as in: see more info)': 'Mer',
  'You can attach a maximum of {max files number} files.':
    'Du kan bifoga maximalt {max files number} filer.',
  'There are no messages to display.': 'Det finns inga meddelanden att visa.',
  'Your selection did not return any result.': 'Ditt val gav inget resultat.',
  'Ref: Popup header for missing required fields in a form': 'Ange ytterligare information',
  'Ref: Required field': '{ref} är ett nödvändigt fält.',
  email: 'E-post',
  phone: 'Mobilnummer',
  Account: 'konto',
  Contacts: 'Kontakter',
  Content: 'Innehåll',
  Events: 'evenemang',
  Facilities: 'Anläggningar',
  Shop: 'Butik',
  Menu: 'Meny',
  Menus: 'Menyer',
  Order: 'Beställ',
  Orders: 'Beställningar',
  Info: 'Info',
  FAQ: 'Vanliga frågor',
  Feedback: 'Feedback',
  Review: 'Recension',
  Requests: 'Förfrågningar',
  'Request Helpdesk': 'Begär Helpdesk',
  Location: 'Plats',
  Safety: 'Säkerhet',
  FreeCoffee: 'Gratis kaffe',
  Newsfeed: 'Nyhetsflöde',
  MyOrders: 'Mina beställningar',
  LoyaltyStamps: 'Lojalitetsstämplar',
  Stamps: 'Stämplar',
  Stays: 'Vistelse',
  Suppliers: 'Leverantör',
  Surveys: 'Undersökningar',
  notifications: 'meddelanden',
  cart: 'vagn',
  'Ref: Reset your order': 'Återställ din beställning',
  'Ref: My language': 'Mitt språk',
  Validate: 'Bekräfta',
  'contact us': 'kontakta oss',
  'Ref: Start typing': 'Börja skriva',
  Occupancy: 'Beläggning',
  'see less': 'Se mindre',
  'see more': 'Se mer',
  contacts: 'Kontakter',
  Imprint: 'Avtryck',
  'Ref: camera access request title': 'Tillåt åtkomst till kameran',
  'Ref: camera access request body':
    'Du måste aktivera en kamera på den här webbplatsen för att använda QR -kodskannern',
  or: 'eller',
  'Ref: Input required': '{input_name} krävs',
  'Enter a valid email address': 'Ange en giltig e-postadress',
  'Ref: Input error E-mail already exists':
    'Det finns redan ett konto med det här e -postmeddelandet du angav. Logga in istället.',
  'Password must contain': 'Lösenord måste innehålla:',
  'Ref: Input password error strength':
    'Lösenordet måste innehålla minst 8 tecken, 1 nummer, 1 bokstav och 1 specialtecken',
  Password: 'Lösenord',
  'New password': 'Nytt lösenord',
  'Confirm password': 'Bekräfta lösenord',
  'Ref: Password does not match': 'Lösenordet och bekräfta lösenordet matchar inte',
  'Ref: Old password does not match': 'Gammalt lösenord matchar inte',
  'Ref: Password validation min8Characters': 'Minst 8 tecken',
  'Ref: Password validation min1Number': 'Minst en siffra',
  'Ref: Password validation min1Letter': 'Minst 1 brev',
  'Ref: Password validation min1SpecialChar': 'Minst 1 specialkaraktär',
  Continue: 'Fortsätta',
  'Enter a valid phone number': 'Ange ett giltigt telefonnummer',
  'Phone is required': 'Telefonnummer krävs',
  'Go to home': 'Gå till startsidan',
  'Something went wrong': 'Något gick fel',
  'Something went wrong, data not loaded': 'Något gick fel, data inte laddade',
  'Change password': 'ändra lösenord',
  'remove one': 'ta bort en',
  'add one': 'Lägg till ett',
  'Confirm mobile phone': 'Bekräfta mobiltelefonen',
  'Confirm Filters': 'Bekräfta filter',
  'Reset Filters': 'Återställningsfilter',
  '{selectedNum} active filters': '{selectedNum} Aktiva filter',
  'Wrong input icon': 'Fel inmatningsikon',
  'Correct input icon': 'Rätt inmatningsikon',
  'Show input text icon': 'Visa inmatningstexikon',
  'Hide input text icon': 'Dölj inmatningstexikonen',
  'Required fields info': '* Obligatoriska fält',
  'Ref: Select site on the map': 'Välj din webbplats på kartan',
  'Ref: Geolocation disabled':
    'Geolokalisering är inaktiverad. För att se närliggande webbplatser, aktivera det.',
  'Ref: Reaction - LIKE': 'Tycka om',
  'Ref: Reaction - LOVE': 'Kärlek',
  'Ref: Reaction - INTERESTING': 'Intressant',
  'Ref: Reaction - CONGRATE': 'Gratulera',
  'Ref: Share': 'Dela med sig',
  'Ref: Send by Email': 'Skicka via e-post',
  'Ref: Copy the link': 'Kopiera länken',
  'Ref: Link was copied': 'Länk kopierades',
  'Ref: unable to copy url': 'Det går inte att kopiera URL',
  'Ref: External link': 'Extern länk',
  'Ref: Step': 'Steg',
  'input field': 'Inmatningsområde',
  'Ref: Zoom': 'Zoom',
  'Increase hours': 'Öka timmar',
  'Decrease hours': 'Minska timmar',
  Hours: 'Timme',
  Minutes: 'Minuter',
  'Ref: filter label: favorites': 'Visa bara mina favoriter',
  'Ref: filter label: max calories': 'Max kalorier',
  'Ref: filter label: dishes': 'Maträtter',
  'Ref: filter label: allergens': 'Allergener',
  'Ref: filter label: highlight': 'Markera eller dölj filter med diskar',
  'Ref: Highlight': 'Markera',
  'Ref: Hide': 'Dölj',
  'Ref: Vegan': 'Vegan-',
  'Ref: Vegeterian': 'Vegetarian',
  'Ref: Mindful': 'Uppmärksam',
  'Ref: Highlight text':
    'Här kan du välja att hightlight eller gömma rätter på menyn som innehåller vissa allergener',
  'Ref: Just now': 'Precis nu',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} För några minuter sedan',
  'Ref: 1 hour ago': '1 timme sedan',
  'Ref: {amountHours} hours ago': '{amountHours} timmar sedan',
  'Ref: Yesterday at {time}': 'Igår vid {time}',
  'Ref: {date} at {time}': '{date} vid {time}',
  'Ref: filter label: date': 'Datum',
  'Ref: expand menu': 'utöka menyn',
  'Ref: collapse menu': 'kollapsmeny',
  'Ref: My site': 'Min sida',
  'Ref: Recent': 'Nyligen',
  'Ref: Services': 'Tjänster',
  'Ref: products in cart': 'Antal produkter i vagnen',
  'Ref: no product in cart': 'Ingen produkt i vagnen',
  'Ref: Survey': 'Undersökning',
  'Access to camera was denied. Please allow access and try again':
    'Tillgång till kameran nekades. Tillåt åtkomst och försök igen',
  'Add an attachment': 'Lägg till en bilaga',
  'Take a picture': 'Ta en bild',
  'Add a file from your device': 'Lägg till en fil från din enhet',
  'Ref: Loading page content, please wait': 'Laddar sidinnehåll, vänta',
  Star: 'Stjärna',
  Stars: 'Stjärnstjärnor',
  'Accommodation Request': 'Boendebegäran',
  'Ref: Guest Accomodation Request': 'Gästförfrågan',
  'Ref: Search a menu, product or facility': 'Sök en meny, produkt eller anläggning',
  ConnectionLost: 'Nätverksanslutning förlorad',
  ConnectionRestored: 'Nätverksanslutning återställdes',
  'Ref: Skip to main content': 'Hoppa till huvudinnehållet',
  'Ref: No options': 'Inga alternativ',
  'Ref: Global search modal': 'Global sökmodal',
  'Ref: Search a facility': 'Sök en anläggning',
  'Ref: Search a menu or product': 'Sök en meny eller en produkt',
  'Ref: Reaction': 'Reaktion',
  'Ref: times': 'Times',
  'Ref: reaction. Change reaction': 'reaktion. Förändringsreaktion',
  'Ref: No reaction. React': 'Ingen reaktion. Reagera',
  'Ref: LIKE': 'Tycka om',
  'Ref: LOVE': 'Kärlek',
  'Ref: INTERESTING': 'Intressant',
  'Ref: CONGRATE': 'Granska',
  'Ref: Open search': 'Öppna sökrutan',
  'Ref: Close search': 'Stäng sökrutan',
  'Ref: Total file size msg': 'Total filstorlek kan inte överstiga {max file size}',
  'Ref: Oops Title': 'Hoppsan!',
  'Ref: Well Done': 'Bra gjort {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigation Arrow - Fortsätt',
  'Ref: Reload': 'Ladda om',
  'Ref: All Dates': 'Alla datum',
  'Ref: Today': 'I dag',
  'Ref: Validate': 'Bekräfta',
  'Ref: toast error title': 'Hoppsan!',
  'Ref: toast error subtitle': 'Ett fel har inträffat. Var vänlig försök igen.',
  'Ref: Calendar': 'Kalender',
  'Ref: Selected': 'Vald',
  'Ref: Previous Month': 'Förra månaden',
  'Ref: Next Month': 'Nästa månad',
  'This module has been retired': 'Denna modul har tagits bort',
  'Ref: Type at least 3 characters to search': 'Skriv minst 3 tecken för att söka',
};
export default labels;
