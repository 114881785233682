import { makeActionCreatorV2 } from '../../../actions';
import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { clearSiteContext, getUserContext, refreshAccessToken } from '../../Core/actions';
import { setTable } from '../../Order/actions';
import { moduleId, getApiEndpoints } from '../config';
import { ISite } from '../types/sites.types';

import {
  GetSites,
  SetInitialSiteAndContractData,
  SwitchSiteAPIType,
  SwitchSiteMutationData,
  UserContextResult,
} from './api.types';

import { getImagesSrcFromIds } from '@/helpers/images/images.helper';
import { waitSeconds } from '@/helpers/misc';
import accommodationRequestsApi from '@/modules/AccommodationRequest/api';
import userContextApi from '@/modules/Core/api/account/userContextApi';
import dspServiceRequestApi from '@/modules/ServiceRequest/api/dspApi';
import { clearLocalServiceRequest } from '@/modules/ServiceRequest/helpers/helpers';
import mockResponse from '@/modules/Sites/mockResponse';
import { CacheService } from '@/services/CacheService';
import { store } from '@/store/store';

const addLastVisitedSite = makeActionCreatorV2('ADD_LAST_VISITED_SITE', { site: 'site' }, moduleId);
const updatingSuccess = makeActionCreatorV2('SITES_UPDATING_SUCCESS', { site: 'site' }, moduleId);
const apiEndpoints = getApiEndpoints();

const sitesApi = apiEndpoints.api.enhanceEndpoints({ addTagTypes: ['sites'] }).injectEndpoints({
  endpoints: (build) => ({
    getSites: build.query<ISite[], GetSites>({
      queryFn: async (args: GetSites) => {
        const baseQuery = axiosBaseQuery({ baseUrl: apiEndpoints.baseUrl });

        const sitesResponse = await baseQuery({
          url: apiEndpoints.getAvailableSites,
          method: 'get',
          params: { ...args },
          mockData: mockResponse.getSites,
        });

        const { data: { sites } = {} } = sitesResponse;

        const imageIds: string[] = sites
          .filter((site: ISite) => !!site.images?.[0])
          .map((site: ISite) => site.images?.[0]);
        const images = await getImagesSrcFromIds(imageIds);

        return {
          data: sites.map((site: ISite) => {
            const image = images.find((img) => img.id === site.images?.[0]);
            return {
              ...site,
              imgInfo: image ? { src: image.content, alt: site.name } : undefined,
            };
          }),
        };
      },
      providesTags: ['sites'],
    }),

    getPublicSites: build.query<ISite[], GetSites>({
      queryFn: async (args: GetSites) => {
        const baseQuery = axiosBaseQuery({ baseUrl: apiEndpoints.baseUrl });

        const sitesResponse = await baseQuery({
          url: apiEndpoints.getPublicSites,
          method: 'get',
          params: { ...args },
          mockData: mockResponse.getPublicSites,
        });

        const { data: { sites } = {} } = sitesResponse;

        const imageIds: string[] = sites
          .filter((site: ISite) => !!site.images?.[0])
          .map((site: ISite) => site.images?.[0]);
        const images = await getImagesSrcFromIds(imageIds);

        return {
          data: sites.map((site: ISite) => {
            const image = images.find((img) => img.id === site.images?.[0]);
            return {
              ...site,
              imgInfo: image ? { src: image.content, alt: site.name } : undefined,
            };
          }),
        };
      },
      providesTags: ['sites'],
    }),

    selectSite: build.mutation<SwitchSiteAPIType, SwitchSiteMutationData>({
      query: ({ site }) => ({
        url: apiEndpoints.selectSite.replace(':siteId', site.id),
        method: 'post',
        data: { siteId: site.id, site },
        mockData: mockResponse.selectSite,
      }),
      transformResponse: async (response, meta, args) => {
        const dispatch = args.dispatch;
        const siteId = args.site.id;

        const state = store.getState();
        const oldSite = state.Core.context.site;
        const isSSOUser = state.Core.user.isSSOUser ?? false;

        if (!isSSOUser) {
          const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || '';
          await dispatch(refreshAccessToken(refreshToken));
        }

        clearLocalServiceRequest();
        CacheService.getInstance().flush();
        await dispatch(clearSiteContext());

        if (!state?.Order?.cart) {
          await dispatch(setTable());
        }

        if (oldSite) {
          await dispatch(
            addLastVisitedSite({
              site: oldSite,
            })
          );
        }
        dispatch(userContextApi.util.invalidateTags(['userContext']));
        const contextResult: UserContextResult = (await dispatch(
          getUserContext()
        )) as unknown as UserContextResult;
        if (!contextResult.ok) return;
        //the change was not persisted yet (async processing of site update), wait and retry once
        if (contextResult.responseData.site.id !== siteId) {
          dispatch(userContextApi.util.invalidateTags(['userContext']));
          await dispatch(clearSiteContext());
          await dispatch(getUserContext());
          await dispatch(updatingSuccess());
        } else {
          await dispatch(updatingSuccess());
        }

        dispatch(
          dspServiceRequestApi.util.invalidateTags([
            'service_requests_locations',
            'service_requests',
          ])
        );
        dispatch(accommodationRequestsApi.util.invalidateTags(['accommodation-requests']));

        return response.data;
      },
      invalidatesTags: (result, error, { shouldInvalidateCache }) =>
        shouldInvalidateCache ? ['sites'] : [],
    }),

    getSiteByCode: build.query<{ site: ISite }, { code: string }>({
      query: (params) => ({
        url: apiEndpoints.getSiteByCodeUrl,
        method: 'get',
        params,
        mockData: mockResponse.getSiteByCode,
      }),
      providesTags: ['sites'],
    }),

    setInitialSiteAndContract: build.mutation<{}, SetInitialSiteAndContractData>({
      query: ({ siteId }) => ({
        url: apiEndpoints.setInitialSiteAndContract.replace(':siteId', siteId),
        method: 'post',
        data: { siteId },
        mockData: mockResponse.setInitialSiteAndContract,
      }),
      transformResponse: async (response, meta, args) => {
        const dispatch = args.dispatch;
        const siteId = args.siteId;

        const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || '';
        await dispatch(refreshAccessToken(refreshToken));

        clearLocalServiceRequest();
        CacheService.getInstance().flush();
        await dispatch(clearSiteContext());

        dispatch(userContextApi.util.invalidateTags(['userContext']));
        const contextResult: UserContextResult = (await dispatch(
          getUserContext()
        )) as unknown as UserContextResult;

        if (!contextResult.ok) return;
        if (
          !contextResult.responseData.site?.id ||
          contextResult.responseData.site?.id !== siteId
        ) {
          await waitSeconds(2);

          await dispatch(clearSiteContext());
          await dispatch(getUserContext());
        }

        return response.data;
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetSitesQuery,
  useLazyGetSitesQuery,
  useSelectSiteMutation,
  useGetPublicSitesQuery,
  useLazyGetSiteByCodeQuery,
  useSetInitialSiteAndContractMutation,
  usePrefetch,
} = sitesApi;
export default sitesApi;
