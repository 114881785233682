const labels = {
  PrivacyPolicy: 'Privacy Policy',
  TermsOfSale: 'Terms of Sales',
  TermsOfUse: 'Terms & Conditions',
  home: 'Home',
  anytime: 'Anytime',
  anywhere: 'anywhere',
  Download: 'Download',
  scrollRightButton: 'Scroll categories to the right',
  scrollLeftButton: 'Scroll categories to the left',
  calendar: 'Calendar',
  'See more': 'See more',
  'See less': 'See less',
  'from (followed by a date, or date and time)': 'from',
  'to (followed by a date, or date and time)': 'to',
  'on (followed by a date)': 'On',
  'at (followed by time)': 'at',
  'from (followed by a location)': 'from',
  'to (followed by a location)': 'to',
  'on (followed by a site name)': 'On',
  'from (followed by a person)': 'from',
  previous: 'Previous',
  next: 'Next',
  success: 'success',
  failure: 'failure',
  today: 'Today',
  tomorrow: 'Tomorrow',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  everyday: 'Everyday',
  'open all day': 'Open all day',
  'opening hours': 'Opening hours',
  status: 'status',
  'to be confirmed': 'To be confirmed',
  'only (as in filter only this)': 'only',
  edit: 'edit',
  submit: 'Submit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  reset: 'Reset',
  Delete: 'Delete',
  select: 'select',
  switch: 'switch',
  close: 'Close',
  clear: 'clear',
  remove: 'remove',
  logout: 'logout',
  go: 'go',
  'see (as in consult this page or document)': 'see',
  'see all (as in see all items)': 'See all',
  'see more (as in see more details for this item)': 'See more',
  retry: 'retry',
  'Ref: ok': 'Ok',
  'yes (as in yes I accept)': 'Yes',
  'no (as in no I do not accept)': 'No',
  you: 'you',
  'thank you': 'Thank You',
  'sorry (standalone)': 'sorry',
  'to home (as in navigate to the homepage)': 'to home',
  map: 'map',
  list: 'list',
  'Loading...': 'Loading...',
  'Refreshing...': 'Refreshing...',
  'Please select': 'Please select',
  'Switch site': 'Switch site here',
  'File size cannot exceed {max file size}': 'File size cannot exceed {max file size}',
  Filters: 'Filters',
  'Add attachment': 'Add attachment',
  Search: 'Search',
  'More (as in: see more info)': 'More',
  'You can attach a maximum of {max files number} files.':
    'You can attach a maximum of {max files number} files.',
  'There are no messages to display.': 'There are no messages to display.',
  'Your selection did not return any result.': 'Your selection did not return any result.',
  'Ref: Popup header for missing required fields in a form':
    'Please provide additional information',
  'Ref: Required field': '{ref} is a required field.',
  email: 'Email',
  phone: 'Mobile Number',
  Account: 'Account',
  Contacts: 'Contacts',
  Content: 'Content',
  Events: 'Events',
  Facilities: 'Facilities',
  Shop: 'Shop',
  Menu: 'Menu',
  Menus: 'Menus',
  Order: 'Order',
  Orders: 'Orders',
  Info: 'Info',
  FAQ: 'FAQ',
  Feedback: 'Feedback',
  Review: 'Review',
  Requests: 'Requests',
  'Request Helpdesk': 'Request Helpdesk',
  Location: 'Location',
  Safety: 'Safety',
  FreeCoffee: 'Free Coffee',
  Newsfeed: 'Newsfeed',
  MyOrders: 'My Orders',
  LoyaltyStamps: 'Loyalty Stamps',
  Stamps: 'Stamps',
  Stays: 'Stays',
  Suppliers: 'Suppliers',
  Surveys: 'Surveys',
  notifications: 'notifications',
  cart: 'cart',
  'Ref: Reset your order': 'Reset your order',
  'Ref: My language': 'My language',
  Validate: 'Validate',
  'contact us': 'contact us',
  'Ref: Start typing': 'Start typing',
  Occupancy: 'Occupancy',
  'see less': 'See less',
  'see more': 'See more',
  contacts: 'Contacts',
  Imprint: 'Imprint',
  'Ref: camera access request title': 'Allow access to the camera',
  'Ref: camera access request body':
    'You must enable the use of a camera on this site in order to use the QR code scanner',
  or: 'or',
  'Ref: Input required': '{input_name} is required',
  'Enter a valid email address': 'Enter a valid email address',
  'Ref: Input error E-mail already exists':
    'There is already an account with this email you entered. Please login instead.',
  'Password must contain': 'Password must contain:',
  'Ref: Input password error strength':
    'Password must contain at least 8 characters, 1 number, 1 letter and 1 special character',
  Password: 'Password',
  'New password': 'New password',
  'Confirm password': 'Confirm password',
  'Ref: Password does not match': 'The password and confirm password don’t match',
  'Ref: Old password does not match': 'Old password does not match',
  'Ref: Password validation min8Characters': 'At least 8 characters',
  'Ref: Password validation min1Number': 'At least 1 digit',
  'Ref: Password validation min1Letter': 'At least 1 letter',
  'Ref: Password validation min1SpecialChar': 'At least 1 special character',
  Continue: 'Continue',
  'Enter a valid phone number': 'Enter a valid phone number',
  'Phone is required': 'Phone is required',
  'Go to home': 'Go to home',
  'Something went wrong': 'Something went wrong',
  'Something went wrong, data not loaded': 'Something went wrong, data not loaded',
  'Change password': 'Change password',
  'remove one': 'remove one',
  'add one': 'add one',
  'Confirm mobile phone': 'Confirm mobile phone',
  'Confirm Filters': 'Confirm Filters',
  'Reset Filters': 'Reset Filters',
  '{selectedNum} active filters': '{selectedNum} active filters',
  'Wrong input icon': 'Wrong input icon',
  'Correct input icon': 'Correct input icon',
  'Show input text icon': 'Show input text icon',
  'Hide input text icon': 'Hide input text icon',
  'Required fields info': '* Required fields',
  'Ref: Select site on the map': 'Select your site on the map',
  'Ref: Geolocation disabled': 'Geolocation is disabled. To see nearby sites, please enable it.',
  'Ref: Reaction - LIKE': 'Like',
  'Ref: Reaction - LOVE': 'Love',
  'Ref: Reaction - INTERESTING': 'Interesting',
  'Ref: Reaction - CONGRATE': 'Congratulate',
  'Ref: Share': 'Share',
  'Ref: Send by Email': 'Send by Email',
  'Ref: Copy the link': 'Copy the link',
  'Ref: Link was copied': 'Link was copied',
  'Ref: unable to copy url': 'Unable to copy URL',
  'Ref: External link': 'External link',
  'Ref: Step': 'Step',
  'input field': 'input field',
  'Ref: Zoom': 'Zoom',
  'Increase hours': 'Increase hours',
  'Decrease hours': 'Decrease hours',
  Hours: 'Hours',
  Minutes: 'Minutes',
  'Ref: filter label: favorites': 'Show my favourites only',
  'Ref: filter label: max calories': 'Max calories',
  'Ref: filter label: dishes': 'Dishes',
  'Ref: filter label: allergens': 'Allergens',
  'Ref: filter label: highlight': 'Highlight or hide filters with dishes',
  'Ref: Highlight': 'Highlight',
  'Ref: Hide': 'Hide',
  'Ref: Vegan': 'Vegan',
  'Ref: Vegeterian': 'Vegetarian',
  'Ref: Mindful': 'Mindful',
  'Ref: Highlight text':
    'Here you can opt to hightlight or hide dishes on the menu that contain certain allergens',
  'Ref: Just now': 'Just now',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} minutes ago',
  'Ref: 1 hour ago': '1 hour ago',
  'Ref: {amountHours} hours ago': '{amountHours} hours ago',
  'Ref: Yesterday at {time}': 'Yesterday at {time}',
  'Ref: {date} at {time}': '{date} at {time}',
  'Ref: filter label: date': 'Date',
  'Ref: expand menu': 'expand menu',
  'Ref: collapse menu': 'collapse menu',
  'Ref: My site': 'My site',
  'Ref: Recent': 'Recent',
  'Ref: Services': 'Services',
  'Ref: products in cart': 'Number of products in the cart',
  'Ref: no product in cart': 'No product in the cart',
  'Ref: Survey': 'Survey',
  'Access to camera was denied. Please allow access and try again':
    'Access to camera was denied. Please allow access and try again',
  'Add an attachment': 'Add an attachment',
  'Take a picture': 'Take a picture',
  'Add a file from your device': 'Add a file from your device',
  'Ref: Loading page content, please wait': 'Loading page content, please wait',
  Star: 'Star',
  Stars: 'Stars',
  'Accommodation Request': 'Accommodation Request',
  'Ref: Guest Accomodation Request': 'Guest Request',
  'Ref: Search a menu, product or facility': 'Search a menu, product or facility',
  ConnectionLost: 'Network connection lost',
  ConnectionRestored: 'Network connection restored',
  'Ref: Skip to main content': 'Skip to main content',
  'Ref: No options': 'No options',
  'Ref: Global search modal': 'Global search modal',
  'Ref: Search a facility': 'Search a facility',
  'Ref: Search a menu or product': 'Search a menu or product',
  'Ref: Reaction': 'Reaction',
  'Ref: times': 'times',
  'Ref: reaction. Change reaction': 'reaction. Change reaction',
  'Ref: No reaction. React': 'No reaction. React',
  'Ref: LIKE': 'Like',
  'Ref: LOVE': 'Love',
  'Ref: INTERESTING': 'Interesting',
  'Ref: CONGRATE': 'Congrate',
  'Ref: Open search': 'Open the search box',
  'Ref: Close search': 'Close the search box',
  'Ref: Total file size msg': 'Total file size cannot exceed {max file size}',
  'Ref: Oops Title': 'Oops!',
  'Ref: Well Done': 'Well done {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigation arrow - Continue',
  'Ref: Reload': 'Reload',
  'Ref: All Dates': 'All dates',
  'Ref: Today': 'Today',
  'Ref: Validate': 'Validate',
  'Ref: toast error title': 'Oops!',
  'Ref: toast error subtitle': 'An error has occurred, please try again.',
  'Ref: Calendar': 'Calendar',
  'Ref: Selected': 'Selected',
  'Ref: Previous Month': 'Previous Month',
  'Ref: Next Month': 'Next Month',
  'This module has been retired': 'This module has been retired',
  'Ref: Type at least 3 characters to search': 'Type at least 3 characters to search',
};
export default labels;
