import { getAllLegalDocuments } from '../modules/Core/actions';
import { SharedState } from '../modules/Core/types/State.types';
import { store } from '../store';

import {
  getBackgroundDocWorkerInstance,
  scheduleLegalDocsBackgroundCheck,
} from './checkLegalDocsScheduler';
import { waitSeconds } from './misc';

type CoreState = {
  legalDocs: LegalDocsState;
  context: ContextState;
  user: {
    preferredLanguage: {
      languageCode: string;
    };
  };
};

type LegalDocsState = {
  fetchedAt: Date | undefined;
};

type ContextState = {
  currentGeoCode: string;
};

export const startBackgroundCheckForLegalDocsChange = async () => {
  await waitSeconds(10); // postpone schedule to ensure app is fully initialized
  scheduleLegalDocsBackgroundCheck(store.getState().Core.legalDocs.fetchedAt);

  const worker = getBackgroundDocWorkerInstance();
  worker.onmessage = async () => {
    const coreState: CoreState = store.getState().Core;
    const sharedState: SharedState = store.getState().Shared;
    await store.dispatch(
      getAllLegalDocuments({
        geoCode: coreState.context.currentGeoCode || sharedState.geographies.defaultGeoCode,
        languageCode: coreState.user.preferredLanguage.languageCode,
      })
    );
  };
};
