import { cleanup, screen, waitFor, within } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';
import moment from 'moment';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import { useEmailReceiptMutation, usePrintReceiptMutation } from '../../../api';
import { DeliveryOptionType } from '../../../types/cart.types';
import { KioskOrderVariant } from '../../../types/KioskOrder.types';
import { stateFromDev, today, useFilteringData } from '../__mocks/ProductsListMocks';
import ProductsList from '../ProductsList';

import { useGetFilterPreferencesQuery } from '@/modules/Core/api/account/accountPreferencesApi';
import { ISite } from '@/modules/Sites/types/sites.types';

// Mocking
const mockConfiguration = jest.fn();

jest.mock('../../../config', () => ({
  ...jest.requireActual('../../../config'),
  orderConfig: () => mockConfiguration(),
}));

jest.mock('../../../hooks/useScrollPosition', () => ({
  __esModule: true, // This is important for modules that have a default export
  default: () => ({
    contentRef: { current: null },
    saveScrollPosition: () => {},
  }),
}));

const mockHistory = createMemoryHistory();

const mockParams = jest.fn();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
  useParams: () => mockParams(),
}));

const siteId = '71a8dc31-1337-e811-a955-000d3a2bcac2';
jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () =>
    ({
      id: siteId,
      currency: {
        isoCode: 'EUR',
      },
    } as ISite),
}));

const mockUseGetLoyaltySchemesQuery = jest.fn();
const mockUseGetLoyaltySchemesQueryResult = jest.fn();
const mockUseGetUserLoyaltySchemesProgressQuery = jest.fn();
const mockUseLazyGetUserLoyaltySchemesProgressQuery = jest.fn();
const mockUseLazyGetUserLoyaltySchemesProgressQueryResult = jest.fn();

jest.mock('@/modules/LoyaltyStamps/api/api', () => ({
  useGetLoyaltySchemesQuery: () => mockUseGetLoyaltySchemesQuery(),
  useGetUserLoyaltySchemesProgressQuery: () => mockUseGetUserLoyaltySchemesProgressQuery(),
  useLazyGetLoyaltySchemesQuery: () => [
    mockUseGetLoyaltySchemesQuery,
    mockUseGetLoyaltySchemesQueryResult(),
  ],
  useLazyGetUserLoyaltySchemesProgressQuery: () => [
    mockUseLazyGetUserLoyaltySchemesProgressQuery,
    mockUseLazyGetUserLoyaltySchemesProgressQueryResult(),
  ],
}));

jest.mock('../../../../Core/api/account/accountPreferencesApi', () => ({
  useGetFilterPreferencesQuery: jest.fn(),
}));

const mockLogUserSteps = jest.fn();

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: mockLogUserSteps,
  }),
}));

const mockUseMenuVisits = jest.fn();

jest.mock('../../../hooks/useMenusVisits/useMenusVisits', () => ({
  __esModule: true,
  default: () => ({
    menusVisits: [{ menuId: 1, visits: 3 }],
    increaseMenuVisits: mockUseMenuVisits,
  }),
}));

const mockUseFetchSuggestions = jest.fn();

jest.mock('../../../hooks/useSuggestions/useFetchSuggestions', () => ({
  ...jest.requireActual('../../../hooks/useSuggestions/useFetchSuggestions'),
  useFetchSuggestions: () => mockUseFetchSuggestions(),
}));

const mockUseSuggestionsMobilePopup = jest.fn();

jest.mock('../../../hooks/useSuggestions/useSuggestionsMobilePopup', () => ({
  ...jest.requireActual('../../../hooks/useSuggestions/useSuggestionsMobilePopup'),
  useSuggestionsMobilePopup: () => mockUseSuggestionsMobilePopup(),
}));

const mockGetMenus = jest.fn();

jest.mock('../../../actions', () => ({
  ...jest.requireActual('../../../actions'),
  getMenus: () => mockGetMenus(),
}));

const mockUseSetupOptions = jest.fn();
jest.mock('@/helpers/hooks/useSetupOption/useSetupOption', () => ({
  useSetupOption: () => mockUseSetupOptions(),
}));

const mockUseCheckCanOrder = jest.fn();
jest.mock('../../../hooks/useCheckCanOrder/useCheckCanOrder', () => ({
  ...jest.requireActual('../../../hooks/useCheckCanOrder/useCheckCanOrder'),
  useCheckCanOrder: () => mockUseCheckCanOrder(),
}));

const mockGetDefaultFiltersFromCache = jest.fn();
jest.mock('../productList.helper', () => ({
  ...jest.requireActual('../productList.helper'),
  GetDefaultFiltersFromCache: () => mockGetDefaultFiltersFromCache,
}));

const mockUseFiltering = jest.fn();
jest.mock('../../../../../components/templates/ListPage', () => ({
  ...jest.requireActual('../../../../../components/templates/ListPage'),
  useFiltering: () => mockUseFiltering(),
}));

jest.mock('../../ProductScanner', () => () => {
  return <div data-testid="mocked-product-scanner" />;
});

const mockUseLoginStatus = jest.fn();
jest.mock('@/helpers/hooks/useLoginStatus', () => ({
  __esModule: true,
  default: () => ({
    useLoginStatus: () => mockUseLoginStatus(),
  }),
}));

jest.mock('@/modules/Core/hooks/useLanguage', () => ({
  __esModule: true,
  default: () => ({
    currentLanguageCode: 'en-US',
  }),
}));

const mockUseIsSuggestionsEnabled = jest.fn();

jest.mock('../../../widgets/SuggestionsWidget/useIsSuggestionsEnabled', () => ({
  useIsSuggestionsEnabled: () => mockUseIsSuggestionsEnabled(),
}));

const mockGetAllergensFromCache = jest.fn();
jest.mock('../../../helpers/allergens.helper', () => ({
  ...jest.requireActual('../../../helpers/allergens.helper'),
  getAllergensFromCache: () => mockGetAllergensFromCache(),
}));

const mockDispatch = jest.fn();
const mockSelector = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
  useDispatch: () => mockDispatch,
}));

const printReceipt = jest.fn();
const emailReceipt = jest.fn();

jest.mock('../../../api', () => ({
  ...jest.requireActual('../../../api'),
  useEmailReceiptMutation: jest.fn(),
  usePrintReceiptMutation: jest.fn(),
}));

// Configuration & test data
const configuration = {
  areSuggestionsEnabled: true,
  isKioskTitleBar: false,
};

const userLoyaltyschemesProgressData = [{ loyaltySchemeId: 1067, stampsCollected: 0 }];

const loyaltySchemes = [
  {
    id: 1064,
    name: 'Exotic Summer Deal',
    description:
      'Collect 5 stamps for buying Banana, Potato or Granny Smith Apple and get one Pineapple, Strawberries or Onion for free. ',
    productsProvidingStamp: [
      {
        uomId: 30551,
        foodItemId: 28508,
      },
    ],
    productsRedeemable: [
      {
        uomId: 30552,
        foodItemId: 28509,
      },
      {
        uomId: 30635,
        foodItemId: 28586,
      },
    ],
    imageUrl:
      'https://rgukretailrangerpre9277.blob.core.windows.net/loyalty/20230706115314-pineapple.jpg',
    stampsRequiredForRedeem: 5,
    menuIdsProvidingProductsRedeemable: [3329],
    menuIdsProvidingProductsProvidingStamp: [3329],
  },
];

// Alternative state for another scenarios

let altState = JSON.parse(JSON.stringify(stateFromDev));

let menuCopy = altState.Order.menus[0];
menuCopy.isFutureOrdering = false;

altState.Order.menus = [menuCopy];
altState.Order.tableNumber = 'TableXY';
altState.Order.hasSiteChanged = true;

// Alternative filtering data for another scenarios

let altFiltering = JSON.parse(JSON.stringify(useFilteringData));
altFiltering.selectedNum = 4;

const suggestions = [
  {
    menuId: 1,
    menuItemId: 1,
    name: 'Suggested Item 1',
    mealName: 'Suggested Meal',
    foodSubCategoryDescription: 'subcategory subs',
    description: 'suggested item 1 descrp',
    price: '3.14',
    productPortions: [],
    listImage: null,
    detailsImage: null,
    genericCategory: 'Base category',
    productCategory: null,
  },
  {
    menuId: 1,
    menuItemId: 2,
    name: 'Suggested Item 2',
    mealName: 'Suggested Meal 2',
    foodSubCategoryDescription: 'subcategory subs',
    description: 'suggested item 2 descrp',
    price: '1.86',
    productPortions: [],
    listImage: null,
    detailsImage: null,
    genericCategory: 'Base category',
    productCategory: null,
  },
];

const suggestionMobilePopUpData = {
  remainingSeconds: 10,
  showSuggestionPopup: false,
  showProgressBar: false,
  openPopupAndCountDown: jest.fn(),
  isFirstItemScanned: false,
  setIsFirstItemScanned: jest.fn(),
  closePopupResetTime: jest.fn(),
  stopCountdown: jest.fn(),
};

const props = {
  variant: KioskOrderVariant.SUCCESS,
  deliveryOption: DeliveryOptionType.Delivery,
  orderId: '123',
  site: { id: 'siteId' },
  label: (label: string) => label,
};

//Testing

describe('ProductsList component', () => {
  afterAll(async () => cleanup());
  const env = global.process.env;
  describe('with all available data in state ', () => {
    beforeAll(async () => {
      global.process.env = {
        ...env,
        REACT_APP_SHARING_ENABLED: 'true',
      };
    });

    beforeEach(async () => {
      (useEmailReceiptMutation as jest.Mock).mockReturnValue([emailReceipt, {}]);
      (usePrintReceiptMutation as jest.Mock).mockReturnValue([printReceipt, {}]);
    });

    beforeAll(() => {
      // Object.defineProperty to bypass the read-only restriction
      Object.defineProperty(global.navigator, 'clipboard', {
        value: {
          writeText: jest.fn(),
        },
        writable: true,
      });
    });

    beforeEach(async () => {
      mockParams.mockReturnValue({
        facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
        date: today,
        menuId: '3348',
        barcode: undefined,
      });
      mockConfiguration.mockReturnValue(configuration);

      mockUseGetLoyaltySchemesQuery.mockReturnValue({ data: loyaltySchemes, isLoading: false });

      (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
        data: undefined,
        isLoading: false,
      });

      mockUseFetchSuggestions.mockReturnValue({ suggestions, isLoading: false });
      mockUseSuggestionsMobilePopup.mockReturnValue(suggestionMobilePopUpData);
      mockUseSetupOptions.mockReturnValue({
        isActive: true,
        value: 'shareSiteIdentifier',
        setupOption: undefined,
      });
      mockUseCheckCanOrder.mockReturnValue({ canOrder: true, canOrderLoading: false });
      mockGetDefaultFiltersFromCache.mockReturnValue({});
      mockGetAllergensFromCache.mockReturnValue([]);
      mockUseLazyGetUserLoyaltySchemesProgressQuery.mockReturnValue(userLoyaltyschemesProgressData);
      mockUseGetUserLoyaltySchemesProgressQuery.mockReturnValue(userLoyaltyschemesProgressData);
      mockUseFiltering.mockReturnValue(useFilteringData);
      mockUseLoginStatus.mockReturnValue({ isGuest: false });
      mockUseIsSuggestionsEnabled.mockReturnValue(true);

      mockSelector.mockReturnValue(stateFromDev);
    });

    describe('orderable menus with enabled common functionalities', () => {
      renderComponent(ProductsList, { ...props }, stateFromDev);

      it('should correctly display main components of the page', () => {
        expect(screen.queryAllByText('EMEA AT Facility')).toBeTruthy();
        expect(screen.getByText('Today')).toBeTruthy();
        expect(screen.getByText('Menu description')).toBeTruthy();
        expect(screen.queryAllByText('products-list-search-bar')).toBeTruthy();
      });

      it('should display product list description when available', () => {
        expect(screen.getByTestId('product-list-description-show-more-wrapper')).toBeTruthy();
      });

      it('should not displaye inactive headers (scan&go, table info ...)', () => {
        expect(screen.queryByTestId('page-header-scan-and-go')).not.toBeInTheDocument();
        expect(screen.queryByTestId('page-header-table')).not.toBeInTheDocument();
      });

      it('should display secondary actions with sharing button when sharing is set to true and not a kiosk', () => {
        expect(screen.getByTestId('secondary-actions')).toBeTruthy();
        expect(screen.getByTestId('product-list-filter-button-modal')).toBeTruthy();
        expect(screen.getByTestId('share-product-list-link')).toBeTruthy();
        expect(screen.getByTestId('product-list-open-facility-details')).toBeTruthy();
      });

      it('should display available items from selectedMenu and Filter element', () => {
        expect(screen.queryAllByText('Granny Smith Apple')).toBeTruthy();
        expect(screen.queryAllByText('Abacaxi Pineapple')).toBeTruthy();
        expect(screen.queryAllByText('Alice Grape')).toBeTruthy();
      });

      it('should display available moments texts from menu', () => {
        expect(screen.queryAllByText('Fruits and Vegetables')).toBeTruthy();
        expect(screen.queryAllByText('Soups')).toBeTruthy();
      });

      it('should correctly assign menu items into moments', () => {
        const tileLists = screen.getAllByTestId('tile-list');
        expect(tileLists.length).toBe(2);
        expect(within(tileLists[0]).queryByTestId('ProductTile-3348_17206431')).toBeInTheDocument();
        expect(within(tileLists[0]).queryByTestId('ProductTile-3348_17206432')).toBeInTheDocument();
        expect(within(tileLists[0]).queryByTestId('ProductTile-3348_17206433')).toBeInTheDocument();
        expect(
          within(tileLists[0]).queryByTestId('ProductTile-3348_172064321')
        ).toBeInTheDocument();

        expect(
          within(tileLists[1]).queryByTestId('ProductTile-3348_172064319')
        ).toBeInTheDocument();
      });

      it('should not display table section', () => {
        expect(screen.queryByTestId('page-header-table')).not.toBeInTheDocument();
      });

      it('should not display site change notification', () => {
        expect(
          screen.queryByTestId('product-list-site-change-notification-top-content')
        ).toBeFalsy();
      });

      it('should shown back button as future ordering is set to false', () => {
        expect(screen.queryAllByTestId('titlebar-navigation-back-btn')).toBeTruthy();
      });

      it('should show the suggestions in the correct column (isRrMenu true)', () => {
        const columns = screen.getAllByTestId('column');
        expect(columns.length).toBe(2);
        expect(within(columns[1]).getByTestId('suggestion-slider')).toBeTruthy();
      });

      it('should not show the suggestions for mobile ', () => {
        const sliderMobile = screen.getByTestId('slider_mobile');

        expect(sliderMobile).toHaveClass('hideMobileSlider');
      });

      it('should show the action bar (showSuggestionPopup = false)', () => {
        const actionBar = screen.getByTestId('actions-bar');

        expect(actionBar).not.toHaveClass('hideActionsBar');
      });

      it('should open Share modal with generated link for orderable menu', async () => {
        const expectedLink = `http://localhost/module_redirect/BE/shareSiteIdentifier/order?menuId=3348&menuDate=${today}`;
        const shareSecondaryAction = screen.getByTestId('share-product-list-link');

        await userEvent.click(shareSecondaryAction);

        await waitFor(
          () => {
            expect(screen.getByTestId('button-share-to-email')).toBeInTheDocument();
          },
          { timeout: 2000 }
        );

        const button = screen.getByTestId('button-share-copy-clipboard');
        await userEvent.click(button);

        await waitFor(
          () => {
            expect(navigator.clipboard.writeText).toHaveBeenCalledWith(expectedLink);
          },
          { timeout: 4000 }
        );
      });

      it('should display allergen modal', async () => {
        const shareSecondaryAction = screen.getByTestId(
          'product-list-filter-button-modal-open-filter-modal'
        );

        await userEvent.click(shareSecondaryAction);

        await waitFor(
          () => {
            expect(
              screen.getByTestId('product-list-filter-button-modal-filter-modal-modal-button-close')
            ).toBeInTheDocument();
          },
          { timeout: 2000 }
        );
      });

      it('should call for getMenus', () => {
        expect(mockGetMenus).toHaveBeenCalled();
      });

      it('should log user step', () => {
        expect(mockLogUserSteps).toHaveBeenCalled();
      });

      it('should call menuVisits', () => {
        expect(mockUseMenuVisits).toHaveBeenCalled();
      });

      it('should properly open the Facility Detail modal', async () => {
        const button = screen.getByTestId('product-list-open-facility-details');
        await userEvent.click(button);

        await waitFor(() => {
          expect(screen.getByText('Monday, Tuesday, Wednesday, Thursday, Friday')).toBeTruthy();
          expect(screen.getByText('Open all day')).toBeTruthy();
          expect(screen.getByText('123456789')).toBeTruthy();
          expect(screen.getByText('foodretail@facility.com')).toBeTruthy();
          expect(screen.getByText('FoodRetail FacilityDescription')).toBeTruthy();
        });

        const closeButton = screen.getByTestId('facility-details-modal-button-close');
        await userEvent.click(closeButton);
      });
    });

    describe('scan&go menu', () => {
      beforeEach(async () => {
        mockParams.mockReturnValue({
          facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
          date: today,
          menuId: '5390',
          barcode: undefined,
        });
      });

      renderComponent(ProductsList, { ...props }, stateFromDev);

      it('should display scan and go menu', () => {
        expect(screen.queryAllByText('Scan and go menu loaded descrp')).toBeTruthy();
        expect(screen.queryAllByText('Red bull Energy Zero Drink')).toBeTruthy();
      });

      it('should display Scan & Go in title', () => {
        expect(screen.getByTestId('page-header-scan-and-go')).toBeTruthy();
      });

      it('should display Scan & Go in actions bar', () => {
        const scanButton = screen.getByTestId('actions-bar');
        expect(within(scanButton).queryAllByText('Scan barcode')).toBeTruthy();
      });
    });

    describe('with table selected & site changed & only one menu futureOrdering set to false', () => {
      beforeEach(async () => {
        mockParams.mockReturnValue({
          facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
          date: today,
          menuId: '9504',
          barcode: undefined,
        });

        mockSelector.mockReturnValue(altState);
      });

      renderComponent(ProductsList, { ...props }, altState);

      it('should display scan and go menu', () => {
        expect(screen.queryAllByText('Scan and go menu loaded descrp')).toBeTruthy();
        expect(screen.queryAllByText('Red bull Energy Zero Drink')).toBeTruthy();
      });

      it('should not display back button', () => {
        expect(screen.queryByTestId('titlebar-navigation-back-btn')).not.toBeInTheDocument();
      });

      it('should display table number selected', () => {
        const tableHeaderSection = screen.getByTestId('page-header-table');
        expect(tableHeaderSection).toBeTruthy();
        expect(within(tableHeaderSection).getByText('Table number: TableXY')).toBeTruthy();
      });

      it('should display site change notification', () => {
        const topContentNotification = screen.getByTestId(
          'product-list-site-change-notification-top-content'
        );

        expect(
          within(topContentNotification).queryAllByText('You have changed location')
        ).toBeTruthy();
        expect(
          within(topContentNotification).queryAllByText('You are now at Sanofi Ridgefield  ')
        ).toBeTruthy();
      });

      it('should not display products menu description', () => {
        expect(
          screen.queryByTestId('product-list-description-show-more-wrapper')
        ).not.toBeInTheDocument();
      });
    });

    describe('can not order (canOrder = false)', () => {
      beforeEach(async () => {
        mockUseCheckCanOrder.mockReturnValue({ canOrder: false, canOrderLoading: false });
      });

      renderComponent(ProductsList, { ...props }, stateFromDev);

      it('should display notification Service unavailable ', () => {
        const columns = screen.getAllByTestId('column');
        expect(within(columns[0]).getByText('Service unavailable')).toBeTruthy();
        expect(
          within(columns[0]).getByText(
            'Careful! Ordering at this site is unavailable at the moment, please try later.'
          )
        ).toBeTruthy();
      });
    });

    describe('is loading page displayed', () => {
      describe('loading filter preferences', () => {
        beforeEach(async () => {
          (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
            data: undefined,
            isLoading: true,
          });
        });
        renderComponent(ProductsList, { ...props }, stateFromDev);
        it('should display loading page when isLoadingPreferences ', () => {
          expect(screen.getByTestId('loading-page-products-list')).toBeTruthy();
        });
      });

      describe('loading facility menus (isLocked)', () => {
        beforeEach(async () => {
          mockParams.mockReturnValue({
            facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
            date: stateFromDev.Order.cart.date,
            menuId: '9504',
            barcode: undefined,
          });
          altState.Order.locks.getMenusForFacility = true;
          mockSelector.mockReturnValue(altState);
        });

        afterAll(async () => {
          altState.Order.locks.getMenusForFacility = false;
        });

        renderComponent(ProductsList, { ...props }, altState);
        it('should display loading page when facility menus has lock in state ', () => {
          expect(screen.getByTestId('loading-page-products-list')).toBeTruthy();
        });
      });
    });

    describe('user is a guest without orders', () => {
      beforeEach(async () => {
        mockParams.mockReturnValue({
          facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
          date: stateFromDev.Order.cart.date,
          menuId: '9504',
          barcode: undefined,
        });
        mockUseLoginStatus.mockReturnValue({ isGuest: true });
      });

      renderComponent(ProductsList, { ...props }, stateFromDev);

      it('should see a menu as standard user', () => {
        const columns = screen.getAllByTestId('column');
        expect(columns.length).toBe(2);
        expect(within(columns[0]).getByTestId('ProductTile-3348_17206431')).toBeTruthy();
      });

      it('should display Suggestions (empty)', () => {
        const suggestions = screen.getByTestId('suggestion-slider');
        expect(within(suggestions).queryAllByText('No suggestions found')).toBeTruthy();
      });

      it('items should not have info about loyalty stamps', () => {
        const tags = screen.queryAllByAltText('1 purchase = 1 stamp');
        expect(tags.length).toBe(0);
      });
    });

    describe('suggestions container visibility', () => {
      beforeEach(async () => {
        mockParams.mockReturnValue({
          facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
          date: stateFromDev.Order.cart.date,
          menuId: '9504',
          barcode: undefined,
        });
      });

      describe('for Ifm menu (isRrMenu = false)', () => {
        beforeEach(async () => {
          stateFromDev.Order.menus[0].source = 'Ifm';
        });
        afterAll(async () => {
          altState.Order.menus[0].source = 'Rr';
        });
        renderComponent(ProductsList, { ...props }, altState);
        it('should not display the suggestion container ', () => {
          expect(screen.queryByTestId('suggestion-slider')).toBeFalsy();
        });
      });

      describe('for not orderable menu (selectedMenu?.isOrderable = false)', () => {
        beforeEach(async () => {
          altState.Order.menus[0].isOrderable = false;
          stateFromDev.Order.menusType = 2;
        });
        afterAll(async () => {
          altState.Order.menus[0].isOrderable = true;
          stateFromDev.Order.menusType = 1;
        });
        renderComponent(ProductsList, { ...props }, altState);
        it('should not display the suggestion container ', () => {
          expect(screen.queryByTestId('suggestion-slider')).toBeFalsy();
        });

        it('should open Share modal with generated link for menu', async () => {
          const shareSecondaryAction = screen.getByTestId('share-product-list-link');

          await userEvent.click(shareSecondaryAction);

          await waitFor(
            () => {
              expect(screen.getByTestId('button-share-to-email')).toBeInTheDocument();
            },
            { timeout: 2000 }
          );

          const button = screen.getByTestId('button-share-copy-clipboard');
          await userEvent.click(button);

          await waitFor(
            () => {
              expect(navigator.clipboard.writeText).toHaveBeenCalledWith(
                expect.stringMatching(
                  /^http:\/\/localhost\/module_redirect\/BE\/shareSiteIdentifier\/menu\?menuId=9504&menuDate=.+$/
                )
              );
            },
            { timeout: 4000 }
          );
        });
      });

      describe('when suggestions are disabled (areSuggestionsEnabled = false)', () => {
        beforeEach(async () => {
          mockConfiguration.mockReturnValue({
            areSuggestionsEnabled: false,
            isKioskTitleBar: false,
          });
        });

        renderComponent(ProductsList, { ...props }, altState);
        it('should not display the suggestion container ', () => {
          expect(screen.queryByTestId('suggestion-slider')).toBeFalsy();
        });
      });

      describe('when suggestions are not allowed for ProductList (isSuggestionsOnProductList = false)', () => {
        beforeEach(async () => {
          mockUseIsSuggestionsEnabled.mockReturnValue(false);
        });

        renderComponent(ProductsList, { ...props }, stateFromDev);
        it('should not display the suggestion container ', () => {
          expect(screen.queryByTestId('suggestion-slider')).toBeFalsy();
        });
      });
    });

    describe('when selected date changes', () => {
      describe('when selected day is tomorrow', () => {
        beforeEach(async () => {
          mockParams.mockReturnValue({
            facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
            date: moment().add(1, 'days').format('YYYY-MM-DD'),
            menuId: '9504',
            barcode: undefined,
          });
        });

        renderComponent(ProductsList, { ...props }, stateFromDev);

        it('should display text Tomorrow', () => {
          const moment = screen.getByTestId('page-header-moment');
          expect(within(moment).getByText('Tomorrow')).toBeTruthy();
        });
      });

      describe('when selected day 5 days ahead', () => {
        beforeEach(async () => {
          mockParams.mockReturnValue({
            facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
            date: moment().add(5, 'days').format('YYYY-MM-DD'),
            menuId: '9504',
            barcode: undefined,
          });
        });
        const customDate = moment().add(5, 'days').format('ddd, MM/DD/YYYY');
        renderComponent(ProductsList, { ...props }, stateFromDev);

        it('should show Date in expected format', () => {
          const moment = screen.getByTestId('page-header-moment');
          expect(within(moment).getByText(customDate)).toBeTruthy();
        });
      });
    });

    describe('filters', () => {
      describe('when there are allergens in state', () => {
        renderComponent(ProductsList, { ...props }, stateFromDev);

        it('should display correct text for multiple filters on filterButton', () => {
          const filterButton = screen.getByTestId('product-list-filter-button-modal');
          expect(within(filterButton).getByText('Filters (Allergens and more)')).toBeTruthy();
        });
      });

      describe('when there are no allergens in state', () => {
        const keepAllergens = altState.Order.allergens;
        beforeEach(async () => {
          altState.Order.allergens = [];
          mockParams.mockReturnValue({
            facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
            date: today,
            menuId: '9504',
            barcode: undefined,
          });
          mockSelector.mockReturnValue(altState);
        });
        afterAll(async () => {
          altState.Order.allergens = keepAllergens;
        });
        renderComponent(ProductsList, { ...props }, altState);

        it('should display correct text without allergens', () => {
          const filterButton = screen.getByTestId('product-list-filter-button-modal');
          expect(within(filterButton).getByText('Filters')).toBeTruthy();
        });
      });

      describe('when there are no filters in cache and no user filter preferences', () => {
        renderComponent(ProductsList, { ...props }, stateFromDev);

        it('should not display any filters active', () => {
          const badgeNumberOfActiveFilters = screen.queryAllByTestId(
            'product-list-filter-button-modal-open-filter-modal-badge-inner'
          );
          expect(badgeNumberOfActiveFilters.length).toBe(0);
        });
      });

      describe('when there are filters in cache', () => {
        const response = JSON.stringify({
          filter_allergens: { Milk: true, Eggs: true },
          filter_dishes: { vegan: true },
          filter_calories: { '466': true },
          filter_allergens_highlight: { hide: true },
          filter_favorites: { favorites: false },
          filter_moment: {},
          filter_category: {},
        });

        let getItemSpy: jest.SpyInstance<string | null, [string]>;

        beforeEach(() => {
          getItemSpy = jest
            .spyOn(Storage.prototype, 'getItem')
            .mockImplementation((key) =>
              key ===
              'Order_productList_filters_71a8dc31-1337-e811-a955-000d3a2bcac2_Contract Cyclic menu_2024-02-13'
                ? JSON.stringify(response)
                : null
            );

          mockUseFiltering.mockReturnValue(altFiltering);
          mockGetAllergensFromCache.mockReturnValue(['Milk', 'Gluten']);
        });

        afterEach(() => {
          jest.restoreAllMocks();
        });

        renderComponent(ProductsList, { ...props }, stateFromDev);

        it('should display correct text for multiple filters on filterButton', () => {
          expect(getItemSpy).toHaveBeenCalledWith(
            `Order_productList_filters_71a8dc31-1337-e811-a955-000d3a2bcac2_Contract Cyclic menu_${today}`
          );
          expect(response).toEqual(response);
        });

        it('should display badge with number of active filters', async () => {
          await waitFor(
            () => {
              const badgeNumberOfActiveFilters = screen.getByTestId(
                'product-list-filter-button-modal-open-filter-modal-badge-inner'
              );
              expect(within(badgeNumberOfActiveFilters).getByText('4')).toBeTruthy();
            },
            { timeout: 4000 }
          );
        });
      });

      describe('not showing items with allergen when default filter is set that way', () => {
        const response = {
          filter_allergens: { Milk: true, Eggs: true },
          filter_dishes: { vegan: true },
          filter_calories: { '466': true },
          filter_allergens_highlight: { hide: true },
          filter_favorites: { favorites: false },
          filter_moment: {},
          filter_category: {},
        };

        beforeEach(() => {
          mockGetDefaultFiltersFromCache.mockReturnValue(response);
        });

        afterEach(() => {
          jest.restoreAllMocks();
        });

        renderComponent(ProductsList, { ...props }, stateFromDev);

        it('should not display product with selected allergen', () => {
          const tileLists = screen.getAllByTestId('tile-list');
          expect(within(tileLists[1]).queryByTestId('ProductTile-3348_17206431')).toBeFalsy();
        });
      });
    });
  });
});
