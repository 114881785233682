import classNames from 'classnames';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import FormLine from '../../../../../components/atoms/FormLine/FormLine';
import { EmailInput } from '../../../../../components/atoms/Input';
import Card from '../../../../../components/molecules/Card/Card';
import Form from '../../../../../components/molecules/Form';
import { trimFields } from '../../../../../components/molecules/Form/helpers';
import Notification, { NOTIFICATION_LOOK } from '../../../../../components/molecules/Notification';
import SimpleFormPage from '../../../../../components/templates/SimpleFormPage/SimpleFormPage';
import useToggle from '../../../../../helpers/hooks/useToggle';
import { pagePaths } from '../../../config';
import { useCoreTranslation } from '../../../hooks/useCoreTranslation';

import { PasswordForgottenFormFields } from './PasswordForgotten.types';

import { CoverIllustration } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import { useSendForgottenEmailMutation } from '@/modules/Core/api/password/passwordApi';

import styles from '../ResetForgottenPassword.module.css';

const formDefaultValues = {
  email: '',
};

const PasswordForgotten = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);
  const [sendForgottenEmail, { isSuccess }] = useSendForgottenEmailMutation();

  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState<boolean>(false);
  const {
    state: requestError,
    toggleOff: toggleErrorOff,
    toggleOn: toggleErrorOn,
  } = useToggle(false);

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm<PasswordForgottenFormFields>({
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const onSubmit: SubmitHandler<PasswordForgottenFormFields> = async (data) => {
    setSubmitButtonIsLoading(true);

    const trimmedData = trimFields<PasswordForgottenFormFields>(data);

    await sendForgottenEmail(trimmedData);

    if (isSuccess) {
      history.push(pagePaths.ResetEmailSent);
    } else {
      toggleErrorOn();
    }

    setSubmitButtonIsLoading(false);
  };

  const notification = requestError
    ? {
        look: NOTIFICATION_LOOK.ERROR,
        title: label('Ref: Something happened'),
        dismissable: true,
        onDismiss: toggleErrorOff,
      }
    : false;

  return (
    <SimpleFormPage title={label('Ref: Page title')} withNavBar={false} hideAllWidgets>
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            {notification && <Notification inheritStyle={classNames('mb-M')} {...notification} />}
            <Card>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormLine data-testid="password-forgotten-email">
                  <EmailInput
                    control={control}
                    name="email"
                    inputLabel={label('email')}
                    labelFunc={label}
                    data-ci="input-email"
                    data-testid="password-forgotten"
                    required
                    inputMsg={label('Ref: Provide email', {
                      replace: { app_name: process.env.REACT_APP_APP_NAME ?? '' },
                    })}
                  />
                </FormLine>
              </Form>
            </Card>
            <ActionsBar className={styles.actionButton}>
              <Button
                data-testid="continueResetPassword"
                onClick={handleSubmit(onSubmit)}
                loading={submitButtonIsLoading}
                disabled={!isValid}
              >
                {label('Continue')}
              </Button>
            </ActionsBar>
          </div>
        </Column.Main>

        <Column.Complementary className={styles.sideImage}>
          <CoverIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};

export default PasswordForgotten;
