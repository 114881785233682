const mockResponse = {
  getIdentityContext: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        client: 'Sodexo',
        type: 'SAML',
        // * you can leave provider empty to switch off sso if needed
        // provider: '',
        // provider: 'G@tes',
        tokenUrlV2:
          'https://gatespreprod.sodexonet.com/adfs/ls/idpinitiatedsignon.aspx?RelayState=RPID=test.mobile.ifm',
      }),
  },
  getUserContext: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        contactId: '30363be0-7609-e911-a972-000d3a2bcd97',
        firstName: 'Antoine',
        lastName: 'Hubert',
        email: 'apptest1@apptest1.com',
        mobile: '1234567',
        qrCode: '',
        dayOfBirth: '2000-01-18',
        contract: {
          id: '2d99f82e-23c6-e811-a96e-000d3a2bc5c1',
          code: 'FBRKM',
          name: 'Fabrikam',
        },
        site: {
          currency: {
            currencyName: 'US Dollar',
            currencySymbol: '$',
            isoCode: 'USD',
            exchangeRate: 1.0,
            precision: 2,
          },
          id: '4aa09563-a78d-e911-a978-000d3a2bcac2',
          code: 'Test',
          name: 'Amsterdam Headquarter',
          isActive: true,
        },
        preferredLanguage: {
          id: '4bba9048-623f-4d2f-aa81-64da7fac16a4',
          name: 'English',
          displayName: 'English',
          systemLanguage: 1033,
          languageCode: 'en-US',
          lcid: 1033,
        },
        completedAppSetup: false,
        allowContent: '224960000',
        allowOffers: '224960001',
        theme: {
          id: 'a678a88f-dd49-eb11-8fed-0003ff4cb7fc',
          name: 'Shell',
          lastModifiedOn: '2020-12-29T14:10:36Z',
        },
        preferredLocation: {
          id: '4aa09563-a78d-e911-a978-000d3a2bcac2',
          name: 'FMG Hamilton - J - Ground Floor',
          title: 'FMG Hamilton - J - Ground Floor, Jabiru',
          site: {
            id: '4aa09563-a78d-e911-a978-000d3a2bcac2',
            name: 'FMG Hamilton',
          },
        },
      }),
  },
  updateUserContext: {
    ok: true,
    status: 202,
    json: () => Promise.resolve(),
  },
  checkEmailExists: {
    ok: true,
    status: 200,
    json: () => Promise.resolve(),
  },
  sendSupportEmail: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
  setUserRegionCode: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
};

export default mockResponse;
