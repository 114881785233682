const labels = {
  PrivacyPolicy: 'מדיניות פרטיות',
  TermsOfSale: 'תנאי המכירה',
  TermsOfUse: 'תנאים והגבלות',
  home: 'בית',
  anytime: 'בכל עת',
  anywhere: 'בְּכָל מָקוֹם',
  Download: 'הורד',
  scrollRightButton: 'קטגוריות גלילה מימין',
  scrollLeftButton: 'קטגוריות גלילה משמאל',
  calendar: 'לוּחַ שָׁנָה',
  'See more': 'ראה עוד',
  'See less': 'רואים פחות',
  'from (followed by a date, or date and time)': 'מ',
  'to (followed by a date, or date and time)': 'ל',
  'on (followed by a date)': 'עַל',
  'at (followed by time)': 'בְּ-',
  'from (followed by a location)': 'מ',
  'to (followed by a location)': 'ל',
  'on (followed by a site name)': 'עַל',
  'from (followed by a person)': 'מ',
  previous: 'קודם',
  next: 'הבא',
  success: 'הַצלָחָה',
  failure: 'כישלון',
  today: 'היום',
  tomorrow: 'מָחָר',
  Monday: 'יוֹם שֵׁנִי',
  Tuesday: 'יום שלישי',
  Wednesday: 'יום רביעי',
  Thursday: 'יום חמישי',
  Friday: 'יום שישי',
  Saturday: 'יום שבת',
  Sunday: 'יום ראשון',
  everyday: 'כל יום',
  'open all day': 'פָּתוּחַ כָּל הַיוֹם',
  'opening hours': 'שעות פתיחה',
  status: 'סטָטוּס',
  'to be confirmed': 'ממתין לאישור',
  'only (as in filter only this)': 'רק',
  edit: 'לַעֲרוֹך',
  submit: 'שלח',
  cancel: 'לבטל',
  confirm: 'לאשר',
  reset: 'אִתחוּל',
  Delete: 'לִמְחוֹק',
  select: 'בחר',
  switch: 'החלף',
  close: 'סגור',
  clear: 'ברור',
  remove: 'לְהַסִיר',
  logout: 'להתנתק',
  go: 'ללכת',
  'see (as in consult this page or document)': 'לִרְאוֹת',
  'see all (as in see all items)': 'ראה הכל',
  'see more (as in see more details for this item)': 'ראה עוד',
  retry: 'נסה שוב',
  'Ref: ok': 'בסדר',
  'yes (as in yes I accept)': 'כן',
  'no (as in no I do not accept)': 'לא',
  you: 'אתה',
  'thank you': 'תודה',
  'sorry (standalone)': 'מצטער',
  'to home (as in navigate to the homepage)': 'לדף הבית',
  map: 'מַפָּה',
  list: 'רשימה',
  'Loading...': 'טעינה...',
  'Refreshing...': 'עִדכּוּן...',
  'Please select': 'בבקשה תבחר',
  'Switch site': 'החלף את אתר כאן',
  'File size cannot exceed {max file size}': 'גודל הקובץ לא יכול לחרוג מ-{max file size}',
  Filters: 'מסנן',
  'Add attachment': 'הוסף קובץ מצורף',
  Search: 'לחפש',
  'More (as in: see more info)': 'יותר',
  'You can attach a maximum of {max files number} files.':
    'אתה יכול לצרף עד {max files number} קבצים.',
  'There are no messages to display.': 'אין הודעות להציג.',
  'Your selection did not return any result.': 'הבחירה שלך לא החזירה שום תוצאה.',
  'Ref: Popup header for missing required fields in a form': 'אנא ספק מידע נוסף',
  'Ref: Required field': '{ref} הוא שדה חובה.',
  email: 'אימייל',
  phone: 'מספר טלפון',
  Account: 'החשבון שלי',
  Contacts: 'אנשי קשר',
  Content: 'תוֹכֶן',
  Events: 'אירועים',
  Facilities: 'מתקנים',
  Shop: 'לִקְנוֹת',
  Menu: 'תַפרִיט',
  Menus: 'תפריטים',
  Order: 'להזמין',
  Orders: 'הזמנות',
  Info: 'מידע',
  FAQ: 'שאלות נפוצות',
  Feedback: 'משוב',
  Review: 'סקירה',
  Requests: 'בקשות',
  'Request Helpdesk': 'בקש עזרה',
  Location: 'מקום',
  Safety: 'בְּטִיחוּת',
  FreeCoffee: 'קפה חינם',
  Newsfeed: 'הזנת חדשות',
  MyOrders: 'ההזמנות שלי',
  LoyaltyStamps: 'חותמות נאמנות',
  Stamps: 'בולים',
  Stays: 'נשאר',
  Suppliers: 'ספקים',
  Surveys: 'סקרים',
  notifications: 'התראות',
  cart: 'עֲגָלָה',
  'Ref: Reset your order': 'אפס את ההזמנה שלך',
  'Ref: My language': 'השפה שלי',
  Validate: 'לְאַמֵת',
  'contact us': 'צור קשר',
  'Ref: Start typing': 'תתחיל להקליד',
  Occupancy: 'תפוסה',
  'see less': 'ראה פחות',
  'see more': 'ראה עוד',
  contacts: 'אנשי קשר',
  Imprint: 'חוֹתָם',
  'Ref: camera access request title': 'אפשר גישה למצלמה',
  'Ref: camera access request body': 'עליך לאפשר שימוש במצלמה באתר זה על מנת להשתמש בסורק קוד QR',
  or: 'אוֹ',
  'Ref: Input required': '{input_name} נדרש',
  'Enter a valid email address': 'הכנס כתובת אימייל תקינה',
  'Ref: Input error E-mail already exists': 'יש כבר חשבון בדוא"ל זה שהזנת. אנא התחבר במקום זאת.',
  'Password must contain': 'סיסמה חייבת להכיל:',
  'Ref: Input password error strength':
    'הסיסמה חייבת להכיל לפחות 8 תווים, מספר אחד, 1 אות ותו מיוחד',
  Password: 'סיסמה',
  'New password': 'סיסמה חדשה',
  'Confirm password': 'אשר סיסמה',
  'Ref: Password does not match': 'הסיסמה ואישור הסיסמה אינם תואמים',
  'Ref: Old password does not match': 'הסיסמה הישנה אינה תואמת',
  'Ref: Password validation min8Characters': 'לפחות 8 תווים',
  'Ref: Password validation min1Number': 'לפחות ספרה אחת',
  'Ref: Password validation min1Letter': 'לפחות מכתב אחד',
  'Ref: Password validation min1SpecialChar': 'לפחות דמות מיוחדת אחת',
  Continue: 'לְהַמשִׁיך',
  'Enter a valid phone number': 'הזן מספר טלפון תקף',
  'Phone is required': 'נדרש טלפון',
  'Go to home': 'לך הביתה',
  'Something went wrong': 'משהו השתבש',
  'Something went wrong, data not loaded': 'משהו השתבש, הנתונים לא נטענו',
  'Change password': 'שנה סיסמא',
  'remove one': 'הסר אחד',
  'add one': 'הוסף אחד',
  'Confirm mobile phone': 'אשר את הטלפון הנייד',
  'Confirm Filters': 'אשר מסננים',
  'Reset Filters': 'אפס פילטרים',
  '{selectedNum} active filters': '{selectedNum} פילטרים פעילים',
  'Wrong input icon': 'סמל קלט שגוי',
  'Correct input icon': 'סמל קלט נכון',
  'Show input text icon': 'הצג סמל טקסט קלט',
  'Hide input text icon': 'הסתר סמל טקסט קלט',
  'Required fields info': '* שדות חובה',
  'Ref: Select site on the map': 'בחר את האתר שלך במפה',
  'Ref: Geolocation disabled': 'מיקום גיאוגרפי מושבת. כדי לראות אתרים סמוכים, אנא הפעל את זה.',
  'Ref: Reaction - LIKE': 'כמו',
  'Ref: Reaction - LOVE': 'אהבה',
  'Ref: Reaction - INTERESTING': 'מעניין',
  'Ref: Reaction - CONGRATE': 'לְבָרֵך',
  'Ref: Share': 'לַחֲלוֹק',
  'Ref: Send by Email': 'שלח בדוא"ל',
  'Ref: Copy the link': 'העתק את הקישור',
  'Ref: Link was copied': 'הקישור הועתק',
  'Ref: unable to copy url': 'לא ניתן להעתיק את כתובת האתר',
  'Ref: External link': 'קישור חיצוני',
  'Ref: Step': 'שלב',
  'input field': 'שדה קלט',
  'Ref: Zoom': 'תקריב',
  'Increase hours': 'הגדל שעות',
  'Decrease hours': 'ירידה בשעות',
  Hours: 'שעה (ות',
  Minutes: 'דקות',
  'Ref: filter label: favorites': 'הראה את המועדפים שלי בלבד',
  'Ref: filter label: max calories': 'מקס קלוריות',
  'Ref: filter label: dishes': 'כלי אוכל',
  'Ref: filter label: allergens': 'אלרגנים',
  'Ref: filter label: highlight': 'הדגש או הסתר מסננים עם מנות',
  'Ref: Highlight': 'שִׂיא',
  'Ref: Hide': 'להתחבא',
  'Ref: Vegan': 'טִבעוֹנִי',
  'Ref: Vegeterian': 'צִמחוֹנִי',
  'Ref: Mindful': 'זָהִיר',
  'Ref: Highlight text':
    'כאן אתה יכול לבחור hightlight או להסתיר כלים בתפריט המכילים אלרגנים מסוימים',
  'Ref: Just now': 'זֶה עַתָה',
  'Ref: {amountMinutes} minutes ago': '{סכום סכום} לפני דקות',
  'Ref: 1 hour ago': 'לפני שעה',
  'Ref: {amountHours} hours ago': '{amountHours} לפני שעות',
  'Ref: Yesterday at {time}': 'אתמול ב {time}',
  'Ref: {date} at {time}': '{time} ב {date}',
  'Ref: filter label: date': 'תַאֲרִיך',
  'Ref: expand menu': 'הרחב את התפריט',
  'Ref: collapse menu': 'תפריט קריסה',
  'Ref: My site': 'האתר שלי',
  'Ref: Recent': 'לאחרונה',
  'Ref: Services': 'שירותים',
  'Ref: products in cart': 'מספר המוצרים בעגלה',
  'Ref: no product in cart': 'אין מוצר בעגלה',
  'Ref: Survey': 'סֶקֶר',
  'Access to camera was denied. Please allow access and try again':
    'הגישה למצלמה נדחתה. אנא אפשר גישה ונסה שוב',
  'Add an attachment': 'הוסף קובץ מצורף',
  'Take a picture': 'לצלם תמונה',
  'Add a file from your device': 'הוסף קובץ מהמכשיר שלך',
  'Ref: Loading page content, please wait': 'טוען תוכן דף, אנא המתן',
  Star: 'כוכב',
  Stars: 'כוכבים',
  'Accommodation Request': 'בקשת לינה',
  'Ref: Guest Accomodation Request': 'בקשת אורח',
  'Ref: Search a menu, product or facility': 'חפש בתפריט, מוצר או מתקן',
  ConnectionLost: 'חיבור רשת אבד',
  ConnectionRestored: 'שוחזר חיבור רשת',
  'Ref: Skip to main content': 'דלג לתוכן הראשי',
  'Ref: No options': 'אין אפשרויות',
  'Ref: Global search modal': 'מודאל חיפוש גלובלי',
  'Ref: Search a facility': 'חפש במתקן',
  'Ref: Search a menu or product': 'חפש בתפריט או במוצר',
  'Ref: Reaction': 'תְגוּבָה',
  'Ref: times': 'פִּי',
  'Ref: reaction. Change reaction': 'תְגוּבָה. שינוי תגובה',
  'Ref: No reaction. React': 'אין תגובה. לְהָגִיב',
  'Ref: LIKE': 'כמו',
  'Ref: LOVE': 'אהבה',
  'Ref: INTERESTING': 'מעניין',
  'Ref: CONGRATE': 'קונגרד',
  'Ref: Open search': 'פתח את תיבת החיפוש',
  'Ref: Close search': 'סגור את תיבת החיפוש',
  'Ref: Total file size msg': 'גודל הקובץ הכולל לא יכול לעלות על {max file size}',
  'Ref: Oops Title': 'אופס!',
  'Ref: Well Done': 'כל הכבוד {party_emoji}',
  'Ref: Navigation arrow - Continue': 'חץ ניווט - המשך',
  'Ref: Reload': 'לִטעוֹן מִחָדָשׁ',
  'Ref: All Dates': 'כל התאריכים',
  'Ref: Today': 'היום',
  'Ref: Validate': 'לְאַמֵת',
  'Ref: toast error title': 'אופס!',
  'Ref: toast error subtitle': 'אירעה שגיאה. אנא נסו שוב.',
  'Ref: Calendar': 'לוּחַ שָׁנָה',
  'Ref: Selected': 'נבחר',
  'Ref: Previous Month': 'חודש שעבר',
  'Ref: Next Month': 'חודש הבא',
  'This module has been retired': 'מודול זה הופסק',
  'Ref: Type at least 3 characters to search': 'הקלד לפחות 3 תווים לחיפוש',
};
export default labels;
