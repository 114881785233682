import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { get } from 'idb-keyval';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';

import { reactPlugin } from './appInsights';
import { AlertPortal } from './components/molecules/Alert';
import { LOCAL_STORAGE_KEYS } from './constants';
import { APP_ERRORS } from './constants';
import { isIndexDbAvailable } from './helpers/cache/cache';
import { getAppDisplayName } from './helpers/misc';
import history from './history';
import './localization/i18n';
import { getUserContext } from './modules/Core/actions';
import appUpdateApi from './modules/Core/api/appUpdate/appUpdateApi';
import App from './modules/Core/components/App';
import { AppUpdateFlags } from './modules/Core/components/AppUpdatePage/AppUpdatePage.types';
import { pagePaths } from './modules/Core/config';
import { applyTheme } from './modules/Core/helpers/helpers';
import MM from './services/ModulesManager';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
// Temporary disabled for beta version
//import  './firebase/index';

//async because we must wait for local data to be returned to pass it to the store
(async () => {
  //load cached data into the store
  let supportingIndexDb = true;
  try {
    await isIndexDbAvailable();
  } catch (e) {
    supportingIndexDb = false;
  }
  let errors = [...(!supportingIndexDb ? [APP_ERRORS.INDEX_DB_SUPPORT] : [])];
  let initialState = {};
  let applicableCoreCache;
  const currentCoreCacheVersion = require('./modules/Core/config').coreCacheVersion;
  let coreCache = {};

  if (supportingIndexDb) {
    coreCache = await get('Core');
    let sharedCache = await get('Shared');
    const currentSharedCacheVersion = require('./modules/Core/config').sharedCacheVersion;
    if (sharedCache?.version !== currentSharedCacheVersion) sharedCache = undefined;
    const accessTokenValidTill = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_VALID_UNTIL);
    if (accessTokenValidTill > Date.now() || (accessTokenValidTill && !window.navigator.onLine)) {
      if (coreCache?.version === currentCoreCacheVersion) applicableCoreCache = coreCache;
    }
    if (sharedCache?.data) initialState.Shared = sharedCache.data;
    if (applicableCoreCache?.data) initialState.Core = applicableCoreCache.data;
  }

  const store = configureStore(initialState);

  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  // Hotfix for fetching new available services when cache version changes. Force update for GDPR reasons
  if (refreshToken) {
    await store.dispatch(getUserContext());
  }

  if (store.getState()?.Shared?.language?.currentLanguageCode) {
    const code = store.getState().Shared.language.currentLanguageCode;
    document.documentElement.lang = code.substr(0, 2);
  }
  // TODO set one source of truth for applyTheme. i.e. populate JSON into state and overwrite it if theme from API is present for easier reusing across application.
  applyTheme();
  document.title = getAppDisplayName();
  //initialize ModulesManager and load the modules if any
  MM().init();
  if (coreCache?.data) {
    await MM().setupFromServices(coreCache.data.services.list);
  }

  const currentRegionCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE);
  // if region code is not known, API call will fail and app will display Generic error page.
  if (currentRegionCode?.length === 2) {
    // For security reasons check if user has valid version of app if not force him to update screen
    if (Capacitor.isNativePlatform()) {
      const appInfo = await CapacitorApp.getInfo();
      const appId = process.env.REACT_APP_MOBILE_PACKAGE_NAME ?? '';
      const { data: appInformation } = await store.dispatch(
        appUpdateApi.endpoints.getAppUpdate.initiate({
          appId:
            appId.lastIndexOf('.internal') > -1
              ? appId.substring(0, appId.lastIndexOf('.internal'))
              : appId,
          appVersion: appInfo.version,
          platform: Capacitor.getPlatform(),
        })
      );
      if (appInformation?.responseData?.type === AppUpdateFlags.FORCE) {
        history.push(pagePaths.AppUpdate);
      }
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    const axe = await import('@axe-core/react');
    await axe.default(React, ReactDOM, 1000);
  }

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <ReduxProvider store={store}>
      <ToastContainer transition={Flip} />
      <IonApp>
        <Router history={history}>
          <React.StrictMode>
            <IonRouterOutlet>
              <AppInsightsContext.Provider value={reactPlugin}>
                <App errors={errors} />
              </AppInsightsContext.Provider>
            </IonRouterOutlet>
          </React.StrictMode>
        </Router>
      </IonApp>
      <AlertPortal />
    </ReduxProvider>
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
