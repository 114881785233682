import accountApi from './account/accountApi';
import accountPreferencesApi from './account/accountPreferencesApi';
import userContextApi from './account/userContextApi';
import appUpdateApi from './appUpdate/appUpdateApi';
import geolocationsApi from './geolocations/geolocationsApi';
import notificationsApi from './notifications/notificationsApi';
import vendorApi from './vendor/vendorApi';

const apis = {
  ...notificationsApi,
  ...vendorApi,
  ...accountPreferencesApi,
  ...userContextApi,
  ...accountApi,
  ...appUpdateApi,
  ...geolocationsApi,
};
export default apis;
