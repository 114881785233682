import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import {
  LocalNotifications,
  ActionPerformed as LocalActionPerformed,
} from '@capacitor/local-notifications';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { useCallback, useEffect } from 'react';

import { useRegisterPushNotificationMutation } from '../api/notifications/pushNotificationsApi';
import { getNotificationInitInfo } from '../config';
import redirectToModule from '../helpers/notificationsHelper';

const useNotifications = (contactId: string): void => {
  const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
  const { shouldRegisterAppInNotificationHub } = getNotificationInitInfo();
  const [registerPushNotification] = useRegisterPushNotificationMutation();

  const registerOnHubNotification = useCallback(async () => {
    if (isPushNotificationsAvailable) {
      const [deviceInfo, deviceUniqId] = await Promise.all([Device.getInfo(), Device.getId()]);
      await PushNotifications.addListener('registration', async (token) => {
        await registerPushNotification({
          appId: process.env.REACT_APP_NOTIFICATION_APP_ID!,
          installationId: deviceUniqId.identifier,
          platform: deviceInfo.platform,
          pushChannel: token.value,
        });
      });

      await PushNotifications.addListener(
        'pushNotificationReceived',
        async (notification: PushNotificationSchema) => {
          const platform = Capacitor.getPlatform();

          if (platform === 'android') {
            const title = notification?.title || notification?.data?.title || '';
            const body = notification?.body || notification?.data?.body || '';

            const regardingEntity = notification?.data?.regardingEntity || '';
            const objectId = notification?.data?.objectId || '';
            const url = notification?.data?.url || '';

            // Can't use id to search for message because of https://github.com/zo0r/react-native-push-notification/issues/1947

            const deliveredNotifications = await PushNotifications.getDeliveredNotifications();
            const notificationsToDelete = deliveredNotifications.notifications.filter(
              (item) => item.title === notification.title
            );
            await PushNotifications.removeDeliveredNotifications({
              notifications: notificationsToDelete,
            });

            // There are 3 "types" of notification in android:
            // 1. app is not running
            // 2. app is running but it's in background
            // 3. app is running and it's active
            // notifications of type 3 are not clickable the trick is to remove the native one
            // and replace it with local which is clickable

            await LocalNotifications.schedule({
              notifications: [
                {
                  id: Math.round(+new Date() / 1000), //we need a 32bit integer here (unix timestamp)
                  title,
                  body,
                  extra: { regardingEntity, objectId, url },
                },
              ],
            });
          }
        }
      );

      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          redirectToModule(notification);
        }
      );

      await LocalNotifications.addListener(
        'localNotificationActionPerformed',
        (notification: LocalActionPerformed) => {
          redirectToModule(notification);
        }
      );

      await PushNotifications.addListener('registrationError', (error: any) => {
        console.error('Error on Push registration: ' + JSON.stringify(error));
      });

      await PushNotifications.register();
    }
  }, [isPushNotificationsAvailable, registerPushNotification]);

  const initNotifications = useCallback(async () => {
    const platform = Capacitor.getPlatform();

    if (platform === 'android') {
      let permissionStatus = await PushNotifications.checkPermissions();

      if (permissionStatus.receive === 'prompt') {
        permissionStatus = await PushNotifications.requestPermissions();
      }

      if (permissionStatus.receive === 'granted') {
        await registerOnHubNotification();
      }
    } else {
      const grantNotificationResponse = await PushNotifications.checkPermissions();
      if (grantNotificationResponse.receive !== 'granted') {
        const permission = await PushNotifications.requestPermissions();
        if (permission.receive !== 'denied') {
          await registerOnHubNotification();
        }
      } else {
        await registerOnHubNotification();
      }
    }
  }, [registerOnHubNotification]);

  const init = useCallback(async () => {
    const deviceInfo = await Device.getInfo();
    if (contactId && deviceInfo.platform !== 'web' && shouldRegisterAppInNotificationHub) {
      initNotifications();
    }
  }, [contactId, initNotifications, shouldRegisterAppInNotificationHub]);

  useEffect(() => {
    isPushNotificationsAvailable && init();
  }, [init, isPushNotificationsAvailable]);
};

export default useNotifications;
