const labels = {
  PrivacyPolicy: 'Datenschutz-Bestimmungen',
  TermsOfSale: 'Verkaufsbedingungen',
  TermsOfUse: 'Geschäftsbedingungen',
  home: 'Startseite',
  anytime: 'Jederzeit',
  anywhere: 'irgendwo',
  Download: 'Download',
  scrollRightButton: 'Scrollenkategorien nach rechts',
  scrollLeftButton: 'Scrollenkategorien nach links',
  calendar: 'Kalender',
  'See more': 'Mehr sehen',
  'See less': 'Weniger sehen',
  'from (followed by a date, or date and time)': 'vom',
  'to (followed by a date, or date and time)': 'bis ',
  'on (followed by a date)': 'am',
  'at (followed by time)': 'bei',
  'from (followed by a location)': 'von',
  'to (followed by a location)': 'nach',
  'on (followed by a site name)': 'bis',
  'from (followed by a person)': 'von',
  previous: 'Vorherig',
  next: 'Nächste',
  success: 'Erfolg',
  failure: 'Fehler',
  today: 'Heute',
  tomorrow: 'Morgen',
  Monday: 'Montag',
  Tuesday: 'Dienstag',
  Wednesday: 'Mittwoch',
  Thursday: 'Donnerstag',
  Friday: 'Freitag',
  Saturday: 'Samstag',
  Sunday: 'Sonntag',
  everyday: 'Täglich',
  'open all day': 'Ganztags geöffnet',
  'opening hours': 'Öffnungszeiten',
  status: 'Status',
  'to be confirmed': 'zu bestätigen',
  'only (as in filter only this)': 'nur',
  edit: 'Bearbeiten',
  submit: 'Einreichen',
  cancel: 'Abbrechen',
  confirm: 'Bestätigen',
  reset: 'Zurücksetzen',
  Delete: 'Löschen',
  select: 'Auswählen',
  switch: 'wechseln',
  close: 'Schließen',
  clear: 'leeren',
  remove: 'entfernen',
  logout: 'Ausloggen',
  go: 'gehen',
  'see (as in consult this page or document)': 'sehen',
  'see all (as in see all items)': 'Alles sehen',
  'see more (as in see more details for this item)': 'Mehr sehen',
  retry: 'Wiederholen',
  'Ref: ok': 'In Ordnung',
  'yes (as in yes I accept)': 'Ja',
  'no (as in no I do not accept)': 'Nein',
  you: 'Sie',
  'thank you': 'Dankeschön',
  'sorry (standalone)': 'Entschuldigung',
  'to home (as in navigate to the homepage)': 'zur Startseite',
  map: 'Karte',
  list: 'aufführen',
  'Loading...': 'Wird geladen...',
  'Refreshing...': 'Aktualisierung...',
  'Please select': 'Bitte auswählen',
  'Switch site': 'Hier die Seite wechseln',
  'File size cannot exceed {max file size}':
    'Die Größe der Datei darf {max file size} nicht überschreiten',
  Filters: 'Filter',
  'Add attachment': 'Anhang hinzufügen',
  Search: 'Suche',
  'More (as in: see more info)': 'Mehr',
  'You can attach a maximum of {max files number} files.':
    'Sie können maximal {max files number} Dateien anhängen.',
  'There are no messages to display.': 'Es gibt keine anzuzeigenden Nachrichten',
  'Your selection did not return any result.': 'Ihre Auswahl hat zu keinem Ergebnis geführt',
  'Ref: Popup header for missing required fields in a form':
    'Bitte geben Sie zusätzliche Informationen an',
  'Ref: Required field': '{ref} ist ein Pflichtfeld.',
  email: 'Email',
  phone: 'Handynummer',
  Account: 'Konto',
  Contacts: 'Kontakte',
  Content: 'Inhalt',
  Events: 'Veranstaltungen',
  Facilities: 'Einrichtungen',
  Shop: 'Shop',
  Menu: 'Speisekarte',
  Menus: 'Menüs',
  Order: 'Bestellung',
  Orders: 'Bestellungen',
  Info: 'Info',
  FAQ: 'FAQ',
  Feedback: 'Feedback',
  Review: 'Überprüfung',
  Requests: 'Anfragen',
  'Request Helpdesk': 'Anfordern Helpdesk',
  Location: 'Ort',
  Safety: 'Sicherheit',
  FreeCoffee: 'Kostenloser Kaffee',
  Newsfeed: 'Neuigkeiten',
  MyOrders: 'Meine Bestellungen',
  LoyaltyStamps: 'Loyalitätsmarken',
  Stamps: 'Marken',
  Stays: 'Aufenthalte',
  Suppliers: 'Lieferanten',
  Surveys: 'Umfragen',
  notifications: 'Benachrichtigungen',
  cart: 'Warenkorb',
  'Ref: Reset your order': 'Setzen Sie Ihre Bestellung zurück',
  'Ref: My language': 'Meine Sprache',
  Validate: 'Bestätigen',
  'contact us': 'Kontaktieren Sie uns',
  'Ref: Start typing': 'Anfangen zu tippen',
  Occupancy: 'Belegung',
  'see less': 'Siehe weniger',
  'see more': 'Mehr sehen',
  contacts: 'Kontakte',
  Imprint: 'Impressum',
  'Ref: camera access request title': 'Kamerazugriff erlauben',
  'Ref: camera access request body':
    'Sie müssen die Kamera für den Standort aktivieren, um den QR -Code -Scanner nutzen zu können',
  or: 'oder',
  'Ref: Input required': '{input_name} ist erforderlich',
  'Enter a valid email address': 'Geben sie eine gültige E-Mail-Adresse an',
  'Ref: Input error E-mail already exists':
    'Es gibt bereits ein Konto mit dieser E -Mail, die Sie eingegeben haben. Bitte melden Sie sich stattdessen an.',
  'Password must contain': 'Passwort muss enthalten:',
  'Ref: Input password error strength':
    'Das Passwort muss mindestens 8 Zeichen, 1 Nummer, 1 Buchstabe und 1 spezielles Zeichen enthalten',
  Password: 'Passwort',
  'New password': 'Passwort bestätigen',
  'Confirm password': 'Das Passwort und das Bestätigungskennwort stimmen nicht überein',
  'Ref: Password does not match': 'Mindestens 8 Zeichen',
  'Ref: Old password does not match': 'Mindestens 1 Ziffer',
  'Ref: Password validation min8Characters': 'Mindestens 1 Großbuchstaben',
  'Ref: Password validation min1Number': 'Mindestens 1 Sonderzeichen',
  'Ref: Password validation min1Letter': 'Fortsetzen',
  'Ref: Password validation min1SpecialChar': 'Geben Sie eine gültige Telefonnummer ein',
  Continue: 'Einloggen/Anmelden',
  'Enter a valid phone number': 'Geben Sie eine gültige Telefonnummer ein',
  'Phone is required': 'Telefon ist erforderlich',
  'Go to home': 'Zur Startseite',
  'Something went wrong': 'Etwas ist schief gelaufen',
  'Something went wrong, data not loaded': 'Etwas lief schief, Daten nicht geladen',
  'Change password': 'Passwort ändern',
  'remove one': 'einen entfernen',
  'add one': 'füge eins hinzu',
  'Confirm mobile phone': 'Mobiltelefon bestätigen',
  'Confirm Filters': 'Filter bestätigen',
  'Reset Filters': 'Filter zurücksetzen',
  '{selectedNum} active filters': '{selectedNum} aktive Filter',
  'Wrong input icon': 'Falsches Eingabesymbol',
  'Correct input icon': 'Richtiges Eingabesymbol',
  'Show input text icon': 'Eingabtext -Symbol anzeigen',
  'Hide input text icon': 'Eingabetext -Symbol ausblenden',
  'Required fields info': 'Service-Anfrage',
  'Ref: Select site on the map': 'Wählen Sie Ihre Website auf der Karte aus',
  'Ref: Geolocation disabled':
    'Geolokalisierung ist deaktiviert. Um in der Nähe von Standorten zu sehen, aktivieren Sie dies bitte.',
  'Ref: Reaction - LIKE': 'Mögen',
  'Ref: Reaction - LOVE': 'Liebe',
  'Ref: Reaction - INTERESTING': 'Interessant',
  'Ref: Reaction - CONGRATE': 'Gratulieren',
  'Ref: Share': 'Teilen',
  'Ref: Send by Email': 'Per E-Mail gesendet',
  'Ref: Copy the link': 'Kopieren Sie den Link',
  'Ref: Link was copied': 'Link wurde kopiert',
  'Ref: unable to copy url': 'URL kann nicht kopiert werden',
  'Ref: External link': 'Externer Link',
  'Ref: Step': 'Schritt',
  'input field': 'Eingabefeld',
  'Ref: Zoom': 'Zoomen',
  'Increase hours': 'Stunden erhöhen',
  'Decrease hours': 'Stunden abnehmen',
  Hours: 'Std',
  Minutes: 'Protokoll',
  'Ref: filter label: favorites': 'Zeigen Sie nur meine Favoriten',
  'Ref: filter label: max calories': 'Max Kalorien',
  'Ref: filter label: dishes': 'Teller',
  'Ref: filter label: allergens': 'Allergenen',
  'Ref: filter label: highlight': 'Filter mit Filtern markieren oder ausblenden',
  'Ref: Highlight': 'Markieren',
  'Ref: Hide': 'Ausblenden',
  'Ref: Vegan': 'Veganer',
  'Ref: Vegeterian': 'Vegetarier',
  'Ref: Mindful': 'Achtsam',
  'Ref: Highlight text':
    'Hier können Sie sich entscheiden, Gerichte auf dem Menü zu heben, das bestimmte Allergene enthält',
  'Ref: Just now': 'Soeben',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} Minuten vor Minuten',
  'Ref: 1 hour ago': 'vor 1 Stunde',
  'Ref: {amountHours} hours ago': '{amountHours} vor Stunden',
  'Ref: Yesterday at {time}': 'Gestern bei {time}',
  'Ref: {date} at {time}': '{date} at {time}',
  'Ref: filter label: date': 'Datum',
  'Ref: expand menu': 'Menü erweitern',
  'Ref: collapse menu': 'Zusammenbruchmenü',
  'Ref: My site': 'Meine Seite',
  'Ref: Recent': 'Jüngste',
  'Ref: Services': 'Dienstleistungen',
  'Ref: products in cart': 'Anzahl der Produkte im Karren',
  'Ref: no product in cart': 'Kein Produkt im Karren',
  'Ref: Survey': 'Umfrage',
  'Access to camera was denied. Please allow access and try again':
    'Der Zugang zur Kamera wurde abgelehnt. Bitte erlauben Sie Zugriff und versuchen Sie es erneut',
  'Add an attachment': 'Fügen Sie einen Anhang hinzu',
  'Take a picture': 'Machen Sie ein Foto',
  'Add a file from your device': 'Fügen Sie eine Datei von Ihrem Gerät hinzu',
  'Ref: Loading page content, please wait': 'Laden Sie Inhalte in den Seiten, bitte warten Sie',
  Star: 'Stern',
  Stars: 'Sterne',
  'Accommodation Request': 'Unterkunftsanfrage',
  'Ref: Guest Accomodation Request': 'Gastanfrage',
  'Ref: Search a menu, product or facility':
    'Suchen Sie ein Menü, ein Produkt oder eine Einrichtung',
  ConnectionLost: 'Netzwerkverbindung verloren',
  ConnectionRestored: 'Netzwerkverbindung wiederhergestellt',
  'Ref: Skip to main content': 'Überspringen Sie zum Hauptinhalt',
  'Ref: No options': 'Keine Optionen',
  'Ref: Global search modal': 'Globales Suchmodal',
  'Ref: Search a facility': 'Suchen Sie nach einer Einrichtung',
  'Ref: Search a menu or product': 'Suchen Sie ein Menü oder Produkt',
  'Ref: Reaction': 'Reaktion',
  'Ref: times': 'mal',
  'Ref: reaction. Change reaction': 'Reaktion. Reaktion ändern',
  'Ref: No reaction. React': 'Keine Reaktion. Reagieren',
  'Ref: LIKE': 'Wie',
  'Ref: LOVE': 'Liebe',
  'Ref: INTERESTING': 'Interessant',
  'Ref: CONGRATE': 'Herzlichen Glückwunsch',
  'Ref: Open search': 'Öffnen Sie das Suchfeld',
  'Ref: Close search': 'Schließen Sie das Suchfeld',
  'Ref: Total file size msg': 'Die Gesamtdateigröße darf {max file size} nicht überschreiten',
  'Ref: Oops Title': 'Hoppla!',
  'Ref: Well Done': 'Gut gemacht {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigationspfeil - Fahren Sie fort',
  'Ref: Reload': 'Neu laden',
  'Ref: All Dates': 'Alle daten',
  'Ref: Today': 'Heute',
  'Ref: Validate': 'Bestätigen',
  'Ref: toast error title': 'Hoppla!',
  'Ref: toast error subtitle': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
  'Ref: Calendar': 'Kalender',
  'Ref: Selected': 'Ausgewählt',
  'Ref: Previous Month': 'Vorheriger Monat',
  'Ref: Next Month': 'Nächsten Monat',
  'This module has been retired': 'Dieses Modul wurde eingestellt',
  'Ref: Type at least 3 characters to search': 'Geben Sie für die Suche mindestens 3 Zeichen ein',
};
export default labels;
